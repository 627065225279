import { Table } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { sortTableByKey } from '../../utils/common/sorting';


function timestampToTime(timestamp) {
  if (timestamp === 'Timeout') {
    return 'Timeout';
  }
  const date = new Date(timestamp);
  return date.toLocaleTimeString();
}

export default function ProfitLossTable({ data, setSuspiciousCount }) {

  const [tableContent, setTableContent] = useState(data);
  const [sortedHeader, setSortedHeader] = useState('id');
  const [isAsc, setIsAsc] = useState(false);

  function toggleSort(key) {
    if (key === 'expected_loss_dollar') {
      setTableContent(sortTableByKey(data, key, !isAsc, true));
    } else {
      setTableContent(sortTableByKey(data, key, isAsc, true));
    }
    setIsAsc(!isAsc);
    setSortedHeader(key);
  }

  let count = 0;
  useEffect(() => {
    setTableContent(data);
    count = 0;
  }, [data]);


  const rows = tableContent['calendar_week'].map((_, idx) => {
    const isSuspicious = isSuspiciousRow(tableContent.entry_timestamp[idx], tableContent.take_profit_timestamp[idx], tableContent.stop_loss_timestamp[idx], count);
    count = isSuspicious ? count + 1 : count;
    return (
      <tr className={`table-format ${isSuspicious ? 'highlighted-row' : ''}`} key={idx + 1}>
        <td>{tableContent.id[idx]}</td>
        <td>{tableContent.date[idx]}</td>
        <td>{tableContent.market[idx]}</td>
        <td>
          <span style={{
            color: tableContent.status[idx] === "Winner" ? "green" :
              tableContent.status[idx] === "Loser" ? "red" :
                tableContent.status[idx] === "NoEntry" ? "blue" : "inherit",
            backgroundColor: (timestampToTime(tableContent.take_profit_timestamp[idx]) === "Timeout" && timestampToTime(tableContent.stop_loss_timestamp[idx]) === "Timeout") ? "#CCCCCC" :
              tableContent.status[idx] === "Winner" ? "lightgreen" :
                tableContent.status[idx] === "Loser" ? "#FFC8C8" :
                  tableContent.status[idx] === "NoEntry" ? "lightblue" : "inherit",
            borderRadius: "5px",
            padding: "5px",
          }}><strong>{tableContent.status[idx] === "NoEntry" ? 'No Entry' : tableContent.status[idx]}</strong></span>
        </td>
        <td>{tableContent.pl_dollar[idx].toFixed(2)}</td>
        <td>{tableContent.trade_direction[idx]}</td>
        <td>{tableContent.entry[idx]}</td>
        <td>{tableContent.take_profit[idx]}</td>
        <td>{tableContent.stop_loss[idx]}</td>
        <td>{tableContent.expected_win_dollar[idx].toFixed(2)}</td>
        <td>{tableContent.expected_loss_dollar[idx].toFixed(2)}</td>
        <td>{tableContent.crv[idx].toFixed(3)}</td>
        <td>{tableContent.exit_price[idx]}</td>
        <td>{tableContent.entry_timestamp[idx] === 'NoEntry' ? <i class="bi bi-slash-lg" /> : timestampToTime(tableContent.entry_timestamp[idx])}</td>
        <td>{tableContent.take_profit_timestamp[idx] === 'NoEntry' ? <i class="bi bi-slash-lg" /> : timestampToTime(tableContent.take_profit_timestamp[idx])}</td>
        <td>{tableContent.stop_loss_timestamp[idx] === 'NoEntry' ? <i class="bi bi-slash-lg" /> : timestampToTime(tableContent.stop_loss_timestamp[idx])}</td>
      </tr>
    )
  });

  setSuspiciousCount(count);

  const headerMap = {
    'id': '#Id',
    'date': 'Date',
    'market': 'Market',
    'status': 'Status',
    'pl_dollar': 'Return',
    'trade_direction': 'Direction',
    'entry': 'Entry',
    'take_profit': 'Take Profit',
    'stop_loss': 'Stop Loss',
    'expected_win_dollar': 'Expected $-Win',
    'expected_loss_dollar': 'Expected $-Loss',
    'crv': 'CRV',
    'exit_price': 'Exit Price',
    'entry_timestamp': 'Entry',
    'take_profit_timestamp': 'Take Profit',
    'stop_loss_timestamp': 'Stop Loss',
  };

  const headersWithIcon = ['entry_timestamp', 'take_profit_timestamp', 'stop_loss_timestamp'];
  const sortableKeys = ['id', 'expected_win_dollar', 'expected_loss_dollar', 'crv', 'pl_dollar'];

  return (
    <div className='table-responsive table-features' style={{ maxHeight: '800px', overflowY: 'auto' }}>
      <Table hover bordered style={{ whiteSpace: "nowrap" }}>
        <thead className='table-header'>
          <tr className='table-format'>
            {
              Object.entries(headerMap).map(([key, title]) => {
                const isSortable = sortableKeys.includes(key);
                const isHeaderWithIcon = headersWithIcon.includes(key);
                return (
                  <th onClick={isSortable ? () => toggleSort(key) : null}>
                    <span className='table-header-element'>
                      {isHeaderWithIcon ? <i class="bi bi-clock icon-padding"></i> : null}{title}
                      {key === sortedHeader ?
                        <div className={isAsc ? 'arrow-rotate-back' : 'arrow-rotate'}>
                          <i class="bi bi-arrow-down" />
                        </div>
                        :
                        null
                      }
                    </span>
                  </th>
                );
              })
            }
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </div>
  );
}

function isSuspiciousRow(entry_ts, tp_ts, sl_ts) {
  if (entry_ts === 'NoEntry' || (tp_ts === 'Timeout' && sl_ts === 'Timeout')) {
    return false;
  }
  if (entry_ts === tp_ts || entry_ts === sl_ts || tp_ts === sl_ts) {
    return true;
  }
  return false
}