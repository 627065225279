export default function StrategySection({ strategy, sections, setSections }) {

    function toggleList(index) {
        setSections((prevSections) => ({
            ...prevSections,
            [index]: !prevSections[index],
        }));
    }

    return (
        <div className="bot-category">
            <span className="bot-category-name">{strategy}</span>
            <span onClick={() => toggleList(strategy)} className={`chevron-style ${sections[strategy] ? 'arrow-rotate' : 'arrow-rotate-back'}`}>
                <i className="bi bi-chevron-down"></i>
            </span>
        </div>
    );
}