import { Table, Row, Col } from 'react-bootstrap';
import './../../styles/components/Reports.css';
import { useState, useEffect } from 'react';
import { sortTableByKey } from '../../utils/common/sorting';

export default function TradeBreakdownReportTaable({ data, activeYear }) {

  const [tableContent, setTableContent] = useState(data);
  const [sortedHeader, setSortedHeader] = useState('year');
  const [isAsc, setIsAsc] = useState(false);

  function toggleSort(key) {
    if (key === 'total_loss') {
      setTableContent(sortTableByKey(data, key, !isAsc, false));
    } else {
      setTableContent(sortTableByKey(data, key, isAsc, false));
    }
    setIsAsc(!isAsc);
    setSortedHeader(key);
  }

  useEffect(() => {
    setTableContent(data);
  }, [data]);

  const rows = tableContent.year.map((year, idx) => {
    const isHighlighted = year === activeYear;
    return (
      <tr className={`table-format ${isHighlighted ? 'highlighted-row' : ''}`} key={idx}>
        <td>{year}</td>
        <td>
          <Row>
            <Col md={4}>
              {tableContent.total_win[idx].toFixed(0)}
            </Col>
            <Col md={4}>
              <span className='winner'>
                <i class="bi bi-chevron-compact-up" />{tableContent.clean_win[idx].toFixed(0)}
              </span>
            </Col>
            <Col>
              <span className='timeout'>
                <i class="bi bi-chevron-compact-up" />{tableContent.timeout_win[idx].toFixed(0)}
              </span>
            </Col>
          </Row>
        </td>
        <td>
          <Row>
            <Col md={4}>
              {tableContent.total_loss[idx].toFixed(0)}
            </Col>
            <Col md={4}>
              <span className='loser'>
                <i class="bi bi-chevron-compact-down" />{tableContent.clean_loss[idx].toFixed(0)}
              </span>
            </Col>
            <Col>
              <span className='timeout'>
                <i class="bi bi-chevron-compact-down" />{tableContent.timeout_loss[idx].toFixed(0)}
              </span>
            </Col>
          </Row>
        </td>
        <td>
          <Row>
            <Col md={4}>
              {tableContent.total_number_winner_trades[idx]}
            </Col>
            <Col md={4}>
              <span className='winner'>
                <i class="bi bi-chevron-compact-up" />{tableContent.number_winner_trades[idx]}
              </span>
            </Col>
            <Col>
              <span className='timeout'>
                <i class="bi bi-chevron-compact-up" />{tableContent.number_timeout_winner_trades[idx]}
              </span>
            </Col>
          </Row>
        </td>
        <td>
          <Row>
            <Col md={4}>
              {tableContent.total_number_loser_trades[idx]}
            </Col>
            <Col md={4}>
              <span className='loser'>
                <i class="bi bi-chevron-compact-down" />{tableContent.number_loser_trades[idx]}
              </span>
            </Col>
            <Col>
              <span className='timeout'>
                <i class="bi bi-chevron-compact-down" />{tableContent.number_timeout_loser_trades[idx]}
              </span>
            </Col>
          </Row>
        </td>
        <td>
          <Row>
            <Col md={4}>
              {tableContent.total_number_trades[idx]}
            </Col>
            <Col md={4}>
              <span style={{ color: '#8884d8' }}>
                <i class="bi bi-plus" />{tableContent.number_loser_trades[idx] + tableContent.number_winner_trades[idx]}
              </span>
            </Col>
            <Col>
              <span className='timeout'>
                <i class="bi bi-plus" />{tableContent.number_timeout_trades[idx]}
              </span>
            </Col>
          </Row>
        </td>
        <td className='no-entry'>
          {tableContent.number_no_entry[idx]}
        </td>
      </tr>
    );
  });

  const headerMap = {
    'year': 'Year',
    'total_win': 'Total Win',
    'total_loss': 'Total Loss',
    'total_number_winner_trades': 'Winner Trades',
    'total_number_loser_trades': 'Loser Trades',
    'total_number_trades': 'Number of Trades',
    'number_no_entry': 'No Entry'
  };

  return (
    <div className='table-responsive table-features'>
      <Table hover bordered style={{ whiteSpace: "nowrap" }}>
        <thead className='table-header'>
          <tr className='table-format'>
            {
              Object.entries(headerMap).map(([key, title]) => {
                return (
                  <th onClick={() => toggleSort(key)}>
                    <span className='table-header-element'>
                      {title}
                      {key === sortedHeader ?
                        <div className={isAsc ? 'arrow-rotate-back' : 'arrow-rotate'}>
                          <i class="bi bi-arrow-down" />
                        </div>
                        :
                        null
                      }
                    </span>
                  </th>
                );
              })
            }
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </div>
  );
}
