import ProfitLossTable from '../components/Tables/ProfitLossTable';
import './../styles/views/Backtest.css'
import EquityCurve from '../components/EquityCurve/EquityCurve';
import PerformanceReportTable from '../components/Tables/PerformanceReportTable';
import { Col, DropdownButton, Dropdown, Row, Button, Card, Form } from 'react-bootstrap';
import { useEffect, useMemo, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import WinnerLoser from '../components/WinnerLoser';

import NetProfit from '../components/Kpi/NetProfit';
import MaxDrawDown from '../components/Kpi/MaxDrawDown';
import AvgWinByTrade from '../components/Kpi/AvgWinByTrade';
import Crv from '../components/Kpi/Crv';
import TotalWinLoss from '../components/TotalWinLoss';
import TradeBreakdownReportTaable from '../components/Tables/TradeBreakdownReportTable';
import BotSettings from '../components/BotSettings';
import { updateBacktestText } from '../utils/firebase/firestore';
import { downloadCSV } from '../utils/common/download';
import EquityCurveDropDown from '../components/EquityCurve/CheckBoxDropDown';
import StackedEquityCurve from '../components/EquityCurve/StackedEquityCurve';
import { sortString } from '../utils/common/sorting';
import { getAvgWinByTrade, getCrv, getEquityCurve, getMaxDrawDownAbs, getMaxDrawDownRel, getNetProfit, getPerformanceReport, getPnL, getTradeBreakdownReport } from '../utils/common/backtestResponseQuery';
import TradeDistribution from '../components/Charts/TradeDistribution';
import ProfitsByDate from '../components/Charts/ProfitsByDate';

export default function Backtest(props) {
  const [show, setShow] = useState(false);
  const [activeYearsInEquityCurve, setActiveYearsInEquityCurve] = useState([]);
  const [activeMarketsInEquityCurve, setActiveMarketsInEquityCurve] = useState([]);
  const [numberActiveYearsInEquityCurve, setNumberActiveYearsInEquityCurve] = useState(1)
  const [numberActiveMarketsInEquityCurve, setNumberActiveMarketsInEquityCurve] = useState(1)
  const [binSize, setBinSize] = useState(5);
  const [suspiciousCount, setSuspiciousCount] = useState(0);



  const toggleShow = () => setShow((s) => !s);
  async function handleClose() {
    setShow(false);
    if (props.activeItemId && props.backtestRequest?.bot) {
      updateBacktestText(props.currentUser.uid, props.backtestRequest['bot'].toLowerCase(), props.activeItemId, props.text);
    }
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Download as .csv
    </Tooltip>
  );


  useEffect(() => {
    if (props.backtestRequest && props.backtestRequest['years']) {
      const initialState = props.backtestRequest['years']
        .map(year => year.toString())
        .map(year => [year, year === props.currentYear])
        .sort((a, b) => sortString(false, a[0], b[0]));
      setActiveYearsInEquityCurve(initialState);
      setNumberActiveYearsInEquityCurve(1);
    }
  }, [props.currentYear, props.backtestRequest]);

  useEffect(() => {
    if (props.backtestRequest && props.backtestRequest['markets']) {
      const initialState = props.backtestRequest['markets'].map(market => [market, market === props.currentMarket]).sort((a, b) => sortString(true, a[0], b[0]));
      const updatedState = initialState.concat([['Aggregated', 'Aggregated' === props.currentMarket]]);
      setActiveMarketsInEquityCurve(updatedState);
      setNumberActiveMarketsInEquityCurve(1);
    }
  }, [props.currentMarket, props.backtestRequest]);

  return (
    <>
      <Row className='navigation'>
        <Col md={8} className='p-0'>
          <Button variant="outline-dark" onClick={toggleShow} className="me-2">
            <i class="bi bi-gear" style={{ color: '#8884d8' }}></i> Show Bot Settings
          </Button>
          <BotSettings
            backtestRequest={props.backtestRequest}
            show={show}
            onHide={handleClose}
            text={props.text}
            setText={props.setText} />
        </Col>
        <Col md={4} className='p-0'>
          <div className='navigation-dropdown'>
            <div style={{ marginRight: '5px' }}>
              <DropdownButton
                id="dropdown-basic-button"
                title={<span>Market: <span style={{ color: '#8884d8', fontWeight: 'bold' }}>{props.currentMarket}</span></span>}
                variant='outline-dark'>
                {setDropDownMarkets(props.backtestRequest, props.setCurrentMarket)}
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => props.setCurrentMarket('Aggregated')}>Aggregate Markets</Dropdown.Item>
              </DropdownButton>
            </div>
            <DropdownButton
              id="dropdown-basic-button"
              title={<span>Year: <span style={{ color: '#8884d8', fontWeight: 'bold' }}>{props.currentYear}</span></span>}
              variant='outline-dark'>
              {setDropDownYears(props.backtestRequest, props.setCurrentYear)}
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => props.setCurrentYear('All Years')}>All Years</Dropdown.Item>
            </DropdownButton>
          </div>

        </Col>
      </Row>
      <Row className='frames'>
        <Col>
          <div className='kpi'>
            <NetProfit netProfit={getNetProfit(props.backtestResponse, props.currentMarket, props.currentYear)} />
            <MaxDrawDown
              maxDrawDownAbs={getMaxDrawDownAbs(props.backtestResponse, props.currentMarket, props.currentYear)}
              maxDrawDownRel={getMaxDrawDownRel(props.backtestResponse, props.currentMarket, props.currentYear)} />
            <Crv crv={getCrv(props.backtestResponse, props.currentMarket, props.currentYear)} />
            <AvgWinByTrade avgWin={getAvgWinByTrade(props.backtestResponse, props.currentMarket, props.currentYear)} />
          </div>
        </Col>
      </Row>
      <Row className='charts'>
        <Col md={8} className='test-l'>
          <Card className='mb-8'>
            <Card.Header>Equity Curve</Card.Header>
            <Card.Body>
              <Card.Title>
                <div className='equity-curves-selector'>
                  <div style={{ marginRight: '5px' }}>
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic-button" variant='outline-dark'>
                        Markets
                      </Dropdown.Toggle>
                      <EquityCurveDropDown
                        activeElements={activeMarketsInEquityCurve}
                        setActiveElements={setActiveMarketsInEquityCurve}
                        setNumberActiveElements={setNumberActiveMarketsInEquityCurve}
                        currentActiveElement={props.currentMarket}
                      />
                    </Dropdown>
                  </div>
                  {
                    props.currentYear === 'All Years' ? null :
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic-button" variant='outline-dark'>
                          Years
                        </Dropdown.Toggle>
                        <EquityCurveDropDown
                          activeElements={activeYearsInEquityCurve}
                          setActiveElements={setActiveYearsInEquityCurve}
                          setNumberActiveElements={setNumberActiveYearsInEquityCurve}
                          currentActiveElement={props.currentYear}
                        />
                      </Dropdown>
                  }
                </div>
                {
                  (numberActiveYearsInEquityCurve > 1 || numberActiveMarketsInEquityCurve > 1) ?
                    <StackedEquityCurve
                      data={props.backtestResponse}
                      activeYears={activeYearsInEquityCurve}
                      activeMarkets={activeMarketsInEquityCurve}
                      currentYear={props.currentYear}
                      currentMarket={props.currentMarket}
                    />
                    :
                    <EquityCurve data={getEquityCurve(props.backtestResponse, props.currentMarket, props.currentYear)} />
                }
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col className='test-r'>
          <Card className='mb-4'>
            <Card.Header>Trade Breakdown</Card.Header>
            <Card.Body>
              <Card.Title>
                <WinnerLoser data={getTradeBreakdownReport(props.backtestResponse, props.currentMarket, props.currentYear)} year={props.currentYear} />
                <TotalWinLoss data={getTradeBreakdownReport(props.backtestResponse, props.currentMarket, props.currentYear)} year={props.currentYear} />
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className='frames'>
        <Col>
          <Card className='mb-4'>
            <Card.Header>Trade Distribution</Card.Header>
            <Card.Body>
              <Card.Title>
                <div className='equity-curves-selector'>
                  <div style={{ marginRight: '5px' }}>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={<span>Bin Size: <span style={{ color: '#8884d8', fontWeight: 'bold'}}>{binSize} USD</span></span>}
                      variant='outline-dark'>
                      {setBinSizes(setBinSize)}
                    </DropdownButton>
                  </div>
                </div>
                <TradeDistribution data={getPnL(props.backtestResponse, props.currentMarket, props.currentYear)} binSize={binSize} />
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className='frames'>
        <Col>
          <Card className='mb-4'>
            <Card.Header>Profits by Date</Card.Header>
            <Card.Body>
              <Card.Title>
                <ProfitsByDate data={getPnL(props.backtestResponse, props.currentMarket, props.currentYear)} />
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className='frames'>
        <Col>
          <Card className='mb-8'>
            <Card.Header>
              <div className='table-view'>
                Performance Report
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <Button className='download-btn' variant="outline-dark" onClick={() => downloadCSV(props.backtestResponse, 'performance_report', props.currentYear, props.currentMarket)}>
                    <i class="bi bi-download download-btn-icon" />
                  </Button>
                </OverlayTrigger>
              </div>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <PerformanceReportTable data={getPerformanceReport(props.backtestResponse, props.currentMarket, props.currentYear)} activeYear={props.currentYear} />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className='frames'>
        <Col>
          <Card className='mb-8'>
            <Card.Header>
              <div className='table-view'>
                Trade Breakdown Report
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <Button className='download-btn' variant="outline-dark" onClick={() => downloadCSV(props.backtestResponse, 'trade_breakdown_report', props.currentYear, props.currentMarket)}>
                    <i class="bi bi-download download-btn-icon" />
                  </Button>
                </OverlayTrigger>
              </div>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <TradeBreakdownReportTaable data={getTradeBreakdownReport(props.backtestResponse, props.currentMarket, props.currentYear)} activeYear={props.currentYear} />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className='frames'>
        <Col>
          <Card className='mb-8'>
            <Card.Header>
              <div className='table-view'>
                Profit Loss Report ({suspiciousCount} suspicious trades)
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <Button className='download-btn' variant="outline-dark" onClick={() => downloadCSV(props.backtestResponse, 'pnl_statement', props.currentYear, props.currentMarket)}>
                    <i class="bi bi-download download-btn-icon" />
                  </Button>
                </OverlayTrigger>
              </div>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <ProfitLossTable data={getPnL(props.backtestResponse, props.currentMarket, props.currentYear)} setSuspiciousCount={setSuspiciousCount} />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

function setDropDownMarkets(backtestRequest, setCurrentMarket) {
  return (
    <>
      {typeof backtestRequest['markets'] === 'undefined' ? (
        <Dropdown.Item>No Markets Available</Dropdown.Item>
      ) : (
        backtestRequest['markets'].sort((a, b) => sortString(true, a, b)).map((market) => (
          <Dropdown.Item onClick={() => setCurrentMarket(market)}>
            {market}
          </Dropdown.Item>
        ))
      )}
    </>
  );
}

function setDropDownYears(backtestRequest, setCurrentYear) {
  return (
    <>
      {typeof backtestRequest['years'] === 'undefined' ? (
        <Dropdown.Item href="#/action-1">No Years Available</Dropdown.Item>
      ) : (
        backtestRequest['years']
          .sort((a, b) => b - a) // Sort the years in descending order
          .map((year) => (
            <Dropdown.Item onClick={() => setCurrentYear(year.toString())}>{year}</Dropdown.Item>
          ))
      )}
    </>
  );
}

function setBinSizes(setBinSize) {
  const binSizes = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];
  return (
    binSizes.map((binSize) => (
      <Dropdown.Item onClick={() => setBinSize(binSize)}>{binSize}</Dropdown.Item>
    ))
  );
}