import { useEffect, useState } from "react";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianAxis, CartesianGrid, Tooltip, Label, Legend, ResponsiveContainer } from 'recharts';


export default function TradeDistribution({ data, binSize }) {
    const [profits, setProfits] = useState(dataToPlot(data['pl_dollar'], 5));

    useEffect(() => {
        const normalizedProfits = dataToPlot(data['pl_dollar'], binSize);
        setProfits(normalizedProfits);
    }, [binSize, data]);

    return (
        <ResponsiveContainer width="100%" height={400}>
            <BarChart
                width={500}
                height={300}
                data={profits}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 15,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" interval={binSize} hide={false} >
                    <Label value="Profit Range" offset={-10} position="insideBottom" />
                </XAxis>
                <YAxis>
                    <Label value="Trade Count" angle={-90} position="left" offset={10} textAnchor="middle" />
                </YAxis>
                <Tooltip labelFormatter={(label) => label > 0 ? `Trades within [${label},${label + binSize}) USD profit` : `Trades within [${label},${label + binSize}) USD profit`} />
                <Bar dataKey="count" fill="#8884d8" />
            </BarChart>
        </ResponsiveContainer>
    );

}

function dataToPlot(pls, binSize) {
    const normalizedProfitsData = normalizedProfits(pls, binSize);
    const data = [];
    normalizedProfitsData.forEach((value, key) => {
        data.push({
            'name': key,
            'count': value,
        });
    });
    data.sort((a, b) => a.name - b.name);
    return data;
}

function normalizedProfits(pls, binSize) {
    return pls.reduce((acc, pl) => {
        if (pl != 0) {
            const bin = Math.floor(pl / binSize) * binSize;
            acc.set(bin, (acc.get(bin) || 0) + 1);
        }
        return acc;
    }, new Map());
}