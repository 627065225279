export function getNetProfit(backtestResponse, market, year) {
    if (market === 'Aggregated' && year === 'All Years') {
        return backtestResponse['agg_market_and_agg_year']['performance_report']['net_profit'][0];
    } else if (market === 'Aggregated' && year !== 'All Years') {
        return backtestResponse['agg_market_and_year']['performance_report']['report']['net_profit'][
            backtestResponse['agg_market_and_year']['performance_report']['report']['year'].indexOf(year)];
    } else if (market !== 'Aggregated' && year === 'All Years') {
        return backtestResponse['market_and_agg_year']['performance_report']['reports'][market]['net_profit'][0];
    } else {
        return backtestResponse['market_and_year']['performance_report']['reports'][market]['net_profit'][
            backtestResponse['market_and_year']['performance_report']['reports'][market]['year'].indexOf(year)];
    }
}

export function getMaxDrawDownAbs(backtestResponse, market, year) {
    if (market === 'Aggregated' && year === 'All Years') {
        return backtestResponse['agg_market_and_agg_year']['performance_report']['max_draw_down_abs'][0];
    } else if (market === 'Aggregated' && year !== 'All Years') {
        return backtestResponse['agg_market_and_year']['performance_report']['report']['max_draw_down_abs'][
            backtestResponse['agg_market_and_year']['performance_report']['report']['year'].indexOf(year)];
    } else if (market !== 'Aggregated' && year === 'All Years') {
        return backtestResponse['market_and_agg_year']['performance_report']['reports'][market]['max_draw_down_abs'][0];
    } else {
        return backtestResponse['market_and_year']['performance_report']['reports'][market]['max_draw_down_abs'][
            backtestResponse['market_and_year']['performance_report']['reports'][market]['year'].indexOf(year)];
    }
}

export function getMaxDrawDownRel(backtestResponse, market, year) {
    if (market === 'Aggregated' && year === 'All Years') {
        return backtestResponse['agg_market_and_agg_year']['performance_report']['max_draw_down_rel'][0];
    } else if (market === 'Aggregated' && year !== 'All Years') {
        return backtestResponse['agg_market_and_year']['performance_report']['report']['max_draw_down_rel'][
            backtestResponse['agg_market_and_year']['performance_report']['report']['year'].indexOf(year)];
    } else if (market !== 'Aggregated' && year === 'All Years') {
        return backtestResponse['market_and_agg_year']['performance_report']['reports'][market]['max_draw_down_rel'][0];
    } else {
        return backtestResponse['market_and_year']['performance_report']['reports'][market]['max_draw_down_rel'][
            backtestResponse['market_and_year']['performance_report']['reports'][market]['year'].indexOf(year)];
    }
}

export function getCrv(backtestResponse, market, year) {
    if (market === 'Aggregated' && year === 'All Years') {
        return backtestResponse['agg_market_and_agg_year']['performance_report']['ratio_avg_win_by_avg_loss'][0];
    } else if (market === 'Aggregated' && year !== 'All Years') {
        return backtestResponse['agg_market_and_year']['performance_report']['report']['ratio_avg_win_by_avg_loss'][
            backtestResponse['agg_market_and_year']['performance_report']['report']['year'].indexOf(year)];
    } else if (market !== 'Aggregated' && year === 'All Years') {
        return backtestResponse['market_and_agg_year']['performance_report']['reports'][market]['ratio_avg_win_by_avg_loss'][0];
    } else {
        return backtestResponse['market_and_year']['performance_report']['reports'][market]['ratio_avg_win_by_avg_loss'][
            backtestResponse['market_and_year']['performance_report']['reports'][market]['year'].indexOf(year)];
    }
}

export function getAvgWinByTrade(backtestResponse, market, year) {
    if (market === 'Aggregated' && year === 'All Years') {
        return backtestResponse['agg_market_and_agg_year']['performance_report']['avg_win_by_trade'][0];
    } else if (market === 'Aggregated' && year !== 'All Years') {
        return backtestResponse['agg_market_and_year']['performance_report']['report']['avg_win_by_trade'][
            backtestResponse['agg_market_and_year']['performance_report']['report']['year'].indexOf(year)];
    } else if (market !== 'Aggregated' && year === 'All Years') {
        return backtestResponse['market_and_agg_year']['performance_report']['reports'][market]['avg_win_by_trade'][0];
    } else {
        return backtestResponse['market_and_year']['performance_report']['reports'][market]['avg_win_by_trade'][
            backtestResponse['market_and_year']['performance_report']['reports'][market]['year'].indexOf(year)];
    }
}

export function getEquityCurve(backtestResponse, market, year) {
    if (market === 'Aggregated' && year === 'All Years') {
        return backtestResponse['agg_market_and_agg_year']['equity_curve']['equity_curve'];
    } else if (market === 'Aggregated' && year !== 'All Years') {
        return backtestResponse['agg_market_and_year']['equity_curve']['curves'][year]['equity_curve'];
    } else if (market !== 'Aggregated' && year === 'All Years') {
        return backtestResponse['market_and_agg_year']['equity_curve']['curves'][market]['equity_curve'];
    } else {
        return backtestResponse['market_and_year']['equity_curve']['curves'][market]['curves'][year]['equity_curve'];
    }
}

export function getTradeBreakdownReport(backtestResponse, market, year) {
    if (market === 'Aggregated' && year === 'All Years') {
        return backtestResponse['agg_market_and_agg_year']['trade_breakdown_report'];
    } else if (market === 'Aggregated' && year !== 'All Years') {
        return backtestResponse['agg_market_and_year']['trade_breakdown_report']['report'];
    } else if (market !== 'Aggregated' && year === 'All Years') {
        return backtestResponse['market_and_agg_year']['trade_breakdown_report']['reports'][market];
    } else {
        return backtestResponse['market_and_year']['trade_breakdown_report']['reports'][market];
    }
}

export function getPerformanceReport(backtestResponse, market, year) {
    if (market === 'Aggregated' && year === 'All Years') {
        return backtestResponse['agg_market_and_agg_year']['performance_report'];
    } else if (market === 'Aggregated' && year !== 'All Years') {
        return backtestResponse['agg_market_and_year']['performance_report']['report'];
    } else if (market !== 'Aggregated' && year === 'All Years') {
        return backtestResponse['market_and_agg_year']['performance_report']['reports'][market];
    } else {
        return backtestResponse['market_and_year']['performance_report']['reports'][market];
    }
}

export function getPnL(backtestResponse, market, year) {
    if (market === 'Aggregated' && year === 'All Years') {
        return backtestResponse['agg_market_and_agg_year']['pnl_statement']['pnl_data'];
    } else if (market === 'Aggregated' && year !== 'All Years') {
        return backtestResponse['agg_market_and_year']['pnl_statement']['pnl_data'][year];
    } else if (market !== 'Aggregated' && year === 'All Years') {
        return backtestResponse['market_and_agg_year']['pnl_statement']['pnl_data'][market];
    } else {
        return backtestResponse['market_and_year']['pnl_statement']['pnl_data'][market]['reports'][year];
    }
}