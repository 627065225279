import { getDoc, setDoc, updateDoc, doc, addDoc, collection, deleteDoc, getDocs } from "firebase/firestore";
import { db } from "../../index";

export async function addUser(user) {
    const userRef = doc(db, 'users', user.uid);
    const userData = {
        name: user.displayName,
        remainingTrials: 10,
        isSubscriber: false,
        latestRequest: {
            id: "",
            collection: ""
        },
    };
    await setDoc(userRef, userData);
}

export async function isNewUser(uid) {
    const userRef = doc(db, 'users', uid);
    const userSnap = await getDoc(userRef);
    return !userSnap.exists();
}

export async function decrementRemainingTrials(uid) {
    const userRef = doc(db, 'users', uid);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
        const remaining = userSnap.data().remainingTrials;
        await updateDoc(userRef, {
            remainingTrials: remaining - 1,
        });
    }
}

export async function getRemainingTrials(uid) {
    const userRef = doc(db, 'users', uid);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
        return userSnap.data().remainingTrials;
    } else {
        throw new Error('User not found');
    }
}

export async function getLatestRequest(uid) {
    const userRef = doc(db, 'users', uid);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
        return userSnap.data().latestRequest;
    } else {
        throw new Error('User not found');
    }
}

export async function setLatestRequest(uid, id, collection) {
    const userRef = doc(db, 'users', uid);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
        await updateDoc(userRef, {
            latestRequest: {
                id,
                collection
            }
        });
    }
}

export async function isSubscriber(uid) {
    const userRef = doc(db, 'users', uid);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
        return userSnap.data().isSubscriber;
    } else {
        throw new Error('User not found');
    }
}

// export async function addBacktestDocument(uid, collectionName, isAi, requestJson, responseJson, botName) {
//     const userRef = doc(db, 'users', uid);
//     const backtestData = {
//         botName: botName,
//         request: requestJson,
//         response: responseJson,
//         ai: isAi,
//         text: "",
//     };

//     const docRef = await addDoc(collection(userRef, collectionName), backtestData);
//     setLatestRequest(uid, docRef.id, collectionName);
//     return [docRef.id, collectionName];
// }

export async function addBacktestDocument(uid, collectionName, isAi, requestJson, responseJson, botName) {
    const userRef = doc(db, 'users', uid);
    const response = {
        market_and_year: await uploadResponseData(userRef, 'market_and_year', responseJson),
        agg_market_and_year: await uploadResponseData(userRef, 'agg_market_and_year', responseJson),
        market_and_agg_year: await uploadResponseData(userRef, 'market_and_agg_year', responseJson),
        agg_market_and_agg_year: await uploadResponseData(userRef, 'agg_market_and_agg_year', responseJson),
    };
    const backtestData = {
        botName: botName,
        request: requestJson,
        response,
        ai: isAi,
        text: "",
    };

    const docRef = await addDoc(collection(userRef, collectionName), backtestData);
    setLatestRequest(uid, docRef.id, collectionName);
    return [docRef.id, collectionName];
}

async function uploadResponseData(userRef, field, responseJson) {
    const pnl = responseJson[field]['pnl_statement'];
    const pr = responseJson[field]['performance_report'];
    const tb = responseJson[field]['trade_breakdown_report'];
    const eq = responseJson[field]['equity_curve'];

    const pnlDocRef = await addDoc(collection(userRef, 'pnl_statement'), pnl);
    const prDocRef = await addDoc(collection(userRef, 'performance_report'), pr);
    const tbDocRef = await addDoc(collection(userRef, 'trade_breakdown_report'), tb);
    const eqDocRef = await addDoc(collection(userRef, 'equity_curve'), eq);

    return {
        pnl_statement: pnlDocRef.id,
        performance_report: prDocRef.id,
        trade_breakdown_report: tbDocRef.id,
        equity_curve: eqDocRef.id,
    }
}

export async function loadLatestRequest(uid) {
    const userRef = doc(db, 'users', uid);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
        const latestRequest = userSnap.data().latestRequest;
        const collectionName = latestRequest['collection'];
        const id = latestRequest['id'];
        if (collectionName && id) {
            const backtestRef = doc(collection(userRef, collectionName), id);
            const backtestSnap = await getDoc(backtestRef);
            return backtestSnap.exists() ? await dereferenceResponseFieldOfBacktestSnapshot(userRef, backtestSnap.data()) : {};
        } else {
            return {};
        }
    } else {
        return {};
    }
}

async function dereferenceResponseFieldOfBacktestSnapshot(userRef, snapshot) {
    const response = {};
    for (const [field, data] of Object.entries(snapshot.response)) {
        response[field] = await dereference(userRef, data)
    }

    snapshot.response = response;
    return snapshot;
}

async function dereference(userRef, data) {
    const deref = {};
    for (const [collectionName, id] of Object.entries(data)) {
        const docRef = doc(collection(userRef, collectionName), id);
        const snap = await getDoc(docRef);
        deref[collectionName] = snap.data();
    }

    return deref;
}

export async function loadData(uid, collectionName, id) {
    const userRef = doc(db, 'users', uid);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
        if (collectionName && id) {
            const backtestRef = doc(collection(userRef, collectionName), id);
            const backtestSnap = await getDoc(backtestRef);
            return backtestSnap.exists() ? dereferenceResponseFieldOfBacktestSnapshot(userRef, backtestSnap.data()) : {};
        } else {
            return {};
        }
    } else {
        return {};
    }
}

export async function loadRequestHistory(uid, collectionNames) {
    const userRef = doc(db, 'users', uid);
    const userSnap = await getDoc(userRef);
    let requestHistory = {};
    if (userSnap.exists()) {
        const promises = collectionNames.map(async (collectionName) => {
            const name = collectionName.toLowerCase();
            const querySnapshot = await getDocs(collection(userRef, name));
            const documentIds = querySnapshot.docs.map((doc) => [doc.id, doc.data().botName, doc.data().ai]);
            return [name, documentIds];
        });

        const results = await Promise.all(promises);

        results.forEach(([collectionName, documentIds]) => {
            requestHistory[collectionName.toUpperCase()] = documentIds;
        });
        return requestHistory;
    } else {
        return requestHistory;
    }
}

export async function updateBacktestText(uid, collectionName, id, newText) {
    const userRef = doc(db, 'users', uid);
    const backtestRef = doc(collection(userRef, collectionName), id);
    await updateDoc(backtestRef, { text: newText });
}

export async function deleteBacktestDocument(uid, collectionName, id) {
    const userRef = doc(db, 'users', uid);
    const backtestRef = doc(collection(userRef, collectionName), id);
    const backtestSnap = await getDoc(backtestRef);
    await deleteReferences(userRef, backtestSnap.data());
    await deleteDoc(backtestRef);
}

async function deleteReferences(userRef, snapshot) {
    for (const data of Object.values(snapshot.response)) {
        for (const [collectionName, id] of Object.entries(data)) {
            await deleteReference(userRef, collectionName, id)
        }
    }
}

async function deleteReference(userRef, collectionName, id) {
    await deleteDoc(doc(collection(userRef, collectionName), id))
}


export async function renameBot(uid, collectionName, id, newBotName) {
    const userRef = doc(db, 'users', uid);
    const backtestRef = doc(collection(userRef, collectionName), id);
    await updateDoc(backtestRef, { botName: newBotName });
}

