import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import { useState, useEffect } from 'react';
import "../../styles/components/SideNav/NewBot.css";
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { Col, Row, Spinner } from 'react-bootstrap';
import { addBacktestDocument } from '../../utils/firebase/firestore';


export default function NewBot(props) {
    const [index, setIndex] = useState(0);
    const [validated, setValidated] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const maxIndex = 2;
    const years = [2017, 2018, 2019, 2020, 2021, 2022];
    const cmeMarkets = ['6a', '6b', '6c', '6e', '6j', '6n', '6btc'];
    const emptyRequestMap = {
        "bot_name": "chapaty",
        "strategy": "",
        "data_provider": "",
        "markets": [],
        "years": [],
        "market_simulation_data_kind": "ohlc-1m",
        "time_interval": "none",
        "time_frame": "",
        "stop_loss": {
            "kind": "YourStopLossKind",
            "offset": 0.0
        },
        "take_profit": {
            "kind": "YourTakeProfitKind",
            "offset": 0.0
        }
    };
    const [requestMap, setRequestMap] = useState(emptyRequestMap);

    let tp_risk = 1.0;
    let sl_risk = 1.0;

    function handleBackClick() {
        if (index > 0) {
            setIndex(index - 1);
        }
    }

    function handleNextClick() {
        if (index < maxIndex) {
            setIndex(index + 1);
        }
    }

    async function handleSubmit(event) {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            return false;
        }
        setValidated(true);
        requestMap['bot_name'] = props.botName;
        // const requestJsonAsString = requestMapToJson(requestMap);
        const requestJson = requestMap; //JSON.parse(requestMap);
        setRequestMap(emptyRequestMap);

        // close current modal
        props.onHide();

        // load spinner modal, to show loading
        setModalShow(true);

        const jwtToken = await props.currentUser.getIdToken(); // Get the JWT token
        
        axios.post(
            // 'http://127.0.0.1:8080/request',
            'https://chapaty-web-qdtfmdhxwa-ey.a.run.app/request',
            requestJson,
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`, // Set the Authorization header
                },
            }
        )
            .then(res => {
                // data arrived, we don't need to show the loading spinner anymore
                setModalShow(false);
                const isAi = false;
                props.setBacktestResponse(res.data);
                props.setBacktestRequest(requestJson);
                props.setCurrentMarket(requestJson['markets'][0]);
                props.setCurrentYear(requestJson['years'][0].toString());
                addBacktestDocument(props.currentUser.uid, requestJson['strategy'].toLowerCase(), isAi, requestJson, res.data, props.botName)
                    .then(([id, collectionName]) => {
                        props.setBotRefs((prevBotRefs) => ({
                            ...prevBotRefs,
                            [collectionName.toUpperCase()]: [[id, props.botName, isAi], ...prevBotRefs[collectionName.toUpperCase()] || []]
                        }));
                        props.setLatestRequestId(id);
                        props.setText('');
                        props.setActiveItemId(id);
                    });
                props.setBotName('');
            })
            .catch(err => {
                setModalShow(false);
                console.log(err);
            });
        return true;
    };

    useEffect(() => {
        return () => {
            setIndex(0);
        }
    }, [props.show]);


    // Function to update the requestMap which gets transformed into a JSON to send a Backtesting request
    function handleSelectChange(e) {
        if (e.target.id === 'take_profit_risk') {
            tp_risk = e.target.value === 'More' ? 1.0 : -1.0;
            requestMap['take_profit']['offset'] = Math.abs(requestMap['take_profit']['offset']) * tp_risk;
        } else if (e.target.id === 'stop_loss_risk') {
            sl_risk = e.target.value === 'More' ? 1.0 : -1.0;
            requestMap['stop_loss']['offset'] = Math.abs(requestMap['stop_loss']['offset']) * sl_risk;
        } else {
            // TODO SL and TP sign get mixed up
            if (e.target.id === 'stop_loss_offset') {
                let offset = Math.abs(parseFloat(e.target.value)) * sl_risk;
                requestMap['stop_loss']['offset'] = offset;
            } else if (e.target.id === 'take_profit_offset') {
                let offset = Math.abs(parseFloat(e.target.value)) * tp_risk;
                requestMap['take_profit']['offset'] = offset;
            } else if (e.target.id === 'stop_loss') {
                requestMap['stop_loss']['kind'] = e.target.value;
            } else if (e.target.id === 'take_profit') {
                requestMap['take_profit']['kind'] = e.target.value;
            }

            else {
                setRequestMap((prevRequestMap) => ({ ...prevRequestMap, [e.target.id]: e.target.value }));
            }
        }
    }

    // Function to update the requestMap which gets transformed into a JSON to send a Backtesting request
    function handleSelectMarketChange(e) {
        const market = e.target.value;
        const isChecked = e.target.checked;
        setRequestMap((prevRequestMap) => ({
            ...prevRequestMap,
            ['markets']: isChecked ? [market, ...prevRequestMap['markets']] : prevRequestMap['markets'].filter(val => val !== market),
        }));
    }

    // Function to update the requestMap which gets transformed into a JSON to send a Backtesting request
    function handleSelectYearChange(e) {
        const year = e.target.value;
        const isChecked = e.target.checked;
        setRequestMap((prevRequestMap) => ({
            ...prevRequestMap,
            ['years']: isChecked ? [parseInt(year, 10), ...prevRequestMap['years']] : prevRequestMap['years'].filter(val => val !== year),
        }));
    }

    return (
        <>
            <div>
                <Modal id='waitingForResponse' show={modalShow} centered backdrop="static" keyboard={false}>
                    <Modal.Body>
                        <Spinner animation="border" variant="dark" style={{ width: "4rem", height: "4rem", borderWidth: '10px' }} />
                    </Modal.Body>
                </Modal>
            </div>
            <div className='input'>
                <Modal
                    {...props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    backdrop='static'
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Configure Your Bot
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Carousel
                                    controls={false}
                                    activeIndex={index}
                                    indicators={false}
                                    interval={null}
                                    variant='dark'>
                                    <Carousel.Item>
                                        <Form noValidate validated={validated}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Bot Name</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Enter a name or short description ..."
                                                    value={props.botName}
                                                    onChange={(e) => props.setBotName(e.target.value)}
                                                />
                                            </Form.Group>

                                            <Form.Group className='mb-3'>
                                                <Form.Label>Trading Strategy</Form.Label>
                                                <Form.Select required aria-label="Default select example" id='strategy' onChange={handleSelectChange}>
                                                    <option disabled selected value="">Select from this menu</option>
                                                    <option value="ppp">PPP</option>
                                                    <option disabled value="magneto">MAGNETO</option>
                                                    <option disabled value="postNews">POST NEWS</option>
                                                </Form.Select>
                                            </Form.Group>

                                            <Form.Group className='mb-3'>
                                                <Form.Label>Data Provider</Form.Label>
                                                <Form.Select required aria-label="Default select example" id='data_provider' onChange={handleSelectChange}>
                                                    <option disabled selected value="">Select from this menu</option>
                                                    <option disabled value="binance">Binance</option>
                                                    <option value="cme">CME Group (Top of Book Level 1)</option>
                                                </Form.Select>
                                            </Form.Group>

                                            <Form.Group className='mb-3'>
                                                <Form.Label>Choose Years for Backtesting</Form.Label>
                                                <div>
                                                    {years.map((year, index) => (
                                                        <Form.Check
                                                            inline
                                                            label={year}
                                                            id={`inline-years-${index}`}
                                                            value={year}
                                                            onChange={handleSelectYearChange}
                                                        />
                                                    ))}
                                                </div>
                                            </Form.Group>

                                        </Form>
                                    </Carousel.Item>

                                    <Carousel.Item>
                                        <Form noValidate validated={validated}>
                                            <Form.Group className='mb-3'>
                                                <Form.Label>Granularity</Form.Label>
                                                <Form.Select id='time_frame' required aria-label="Default select example" onChange={handleSelectChange}>
                                                    <option disabled selected value="">Select from this menu</option>
                                                    <option value="1d">Daily</option>
                                                    <option value="1w">Weekly</option>
                                                </Form.Select>
                                            </Form.Group>
                                            <Form.Group className='mb-3'>
                                                <Form.Label>Choose Markets</Form.Label>
                                                <div>
                                                    {cmeMarkets.map((market, index) => (
                                                        <Form.Check
                                                            inline
                                                            label={market}
                                                            id={`inline-cmeMarkets-${index}`}
                                                            value={market}
                                                            onChange={handleSelectMarketChange}
                                                        />
                                                    ))}
                                                </div>
                                            </Form.Group>
                                            {/* <Form.Group className='mb-3'>
                                                <Form.Label>Select Volume Profile</Form.Label>
                                                <Form.Select required aria-label="Data Provider Select" id='vol_data' onChange={handleSelectChange}>
                                                    <option disabled selected value="">Select from this menu</option>
                                                    <option value="Ohlc1m">OHLC based on 1m</option>
                                                    <option value="Tik">Tik Data</option>
                                                    <option value="AggTrades">Aggregated Trades</option>
                                                </Form.Select>
                                            </Form.Group> */}
                                            {/* <Form.Group className='mb-3'>
                                                <Form.Label>Select OHLC Data</Form.Label>
                                                <Form.Select required aria-label="Data Provider Select" id='ohlc_data' onChange={handleSelectChange}>
                                                    <option disabled selected value="">Select from this menu</option>
                                                    <option value="Ohlc60m">OHLC based on 60m</option>
                                                    <option value="Ohlc1m">OHLC based on 1m</option>
                                                </Form.Select>
                                            </Form.Group> */}
                                        </Form>
                                    </Carousel.Item>
                                    {/* <Carousel.Item>
                                        <div className="ai-intro">
                                            <p className="ai-text">
                                                If you opt for our cutting-edge <span className='ai-badge'>AI</span> machine learning automation,
                                                <span className="brand-name"> CHAPATY <span style={{ fontStyle: 'italic' }}>AI</span></span> will leverage its
                                                advanced machine learning algorithms to train your bot behind the
                                                scenes. Through this process, we tailor the bot's parameters precisely
                                                to your unique trading strategy, ensuring optimal performance based on
                                                the specific optimization criteria you prioritize. Our state-of-the-art
                                                technology empowers you to unlock the full potential of AI, enabling you
                                                to achieve superior results in your trading endeavors.
                                            </p>
                                        </div>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Choose your Bot Type:</Form.Label>
                                            <Form.Check
                                                disabled
                                                type="radio"
                                                id="aiBot"
                                                label={<>
                                                    <span className="ai-badge">
                                                        AI
                                                    </span>
                                                    <span>
                                                        Optimized Bot
                                                    </span>
                                                </>}
                                                name="botType"
                                                value="ai"
                                            />
                                            <Form.Check
                                                type="radio"
                                                id="normalBot"
                                                label="Normal Bot"
                                                name="botType"
                                                value="normal"
                                            />

                                        </Form.Group>
                                    </Carousel.Item> */}
                                    <Carousel.Item>
                                        <Row>
                                            <Col>
                                                <Form noValidate validated={validated} >
                                                    <Form.Label style={{ fontWeight: 'bold' }}>Stop Loss</Form.Label>
                                                    <Form.Group className='mb-3'>
                                                        <Form.Label>Exit Type</Form.Label>
                                                        <Form.Select required aria-label="Default select example" id='stop_loss' onChange={handleSelectChange}>
                                                            <option disabled selected value="">Select from this menu</option>
                                                            <option value="PriceUponTradeEntry">Previous Entry Price</option>
                                                            <option value="PrevHighOrLow">Previous Low or High</option>
                                                            <option value="ValueAreaHighOrLow">Value Area High or Low</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                    <Form.Group className='mb-3'>
                                                        <Form.Label>Risk Exposure</Form.Label>
                                                        <Form.Select aria-label="Default select example" id='stop_loss_risk' onChange={handleSelectChange}>
                                                            <option disabled selected value="">Select from this menu</option>
                                                            <option value='More'>More Risk</option>
                                                            <option value='Less'>Less Risk</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                    <Form.Group className='mb-3'>
                                                        <Form.Label>Quantify your Risk Exposure in USD</Form.Label>
                                                        <Form.Control required type='text' placeholder='Amount in USD' id='stop_loss_offset' onChange={handleSelectChange} defaultValue={0} />
                                                    </Form.Group>
                                                </Form>
                                            </Col>
                                            <Col>
                                                <Form noValidate validated={validated}>
                                                    <Form.Label style={{ fontWeight: 'bold' }}>Take Profit</Form.Label>
                                                    <Form.Group required className='mb-3'>
                                                        <Form.Label>Exit Type</Form.Label>
                                                        <Form.Select required aria-label="Default select example" id='take_profit' onChange={handleSelectChange}>
                                                            <option disabled selected value="">Select from this menu</option>
                                                            <option value="PrevClose">Previous Close</option>
                                                            <option value="PriceUponTradeEntry">Previous Entry Price</option>
                                                            <option value="PrevHighOrLow">Previous Low or High</option>
                                                            <option value="ValueAreaHighOrLow">Value Area High or Low</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                    <Form.Group className='mb-3'>
                                                        <Form.Label>Risk Exposure</Form.Label>
                                                        <Form.Select aria-label="Default select example" id='take_profit_risk' onChange={handleSelectChange}>
                                                            <option disabled selected value="">Select from this menu</option>
                                                            <option value='More'>More Risk</option>
                                                            <option value='Less'>Less Risk</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                    <Form.Group className='mb-3'>
                                                        <Form.Label>Quantify your Risk Exposure in USD</Form.Label>
                                                        <Form.Control required type='text' placeholder='Amount in USD' id='take_profit_offset' onChange={handleSelectChange} defaultValue={0} />
                                                    </Form.Group>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Carousel.Item>
                                </Carousel>
                            </Col>
                        </Row>
                        <Row>
                            <div className="input-form-progress">
                                <div className={`circle ${index >= 0 ? 'bg-dark' : ''}`}></div>
                                <div className={`circle ${index >= 1 ? 'bg-dark' : ''}`}></div>
                                <div className={`circle ${index >= 2 ? 'bg-dark' : ''}`}></div>
                                {/* <div className={`circle ${index >= 3 ? 'bg-dark' : ''}`}></div> */}
                            </div>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer className='input-form-control'>
                        <Button variant="outline-dark" onClick={handleBackClick} disabled={index == 0}>Back</Button>

                        {
                            (index == maxIndex) ?
                                <Button variant='dark' type="submit" onClick={handleSubmit}>
                                    Start Backtesting
                                </Button>
                                :
                                <Button variant="outline-dark" type="submit" onClick={handleNextClick}>Next</Button>

                        }
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
}

// function requestMapToJson(map) {
//     const StopLoss = {
//         condition: map['stop_loss'],
//         offset: map['stop_loss']['offset']
//     };

//     const TakeProfit = {
//         condition: map['take_profit'],
//         offset: map['take_profit']['offset']
//     };

//     const requestMap = new Map(Object.entries(map));

//     // Delete stop_loss_offset and take_profit_offset
//     requestMap.delete('stop_loss_offset');
//     requestMap.delete('take_profit_offset');

//     // Replace stop_loss value with StopLoss object
//     requestMap.set('stop_loss', StopLoss);
//     requestMap.set('take_profit', TakeProfit);

//     // Transform map to JSON and return
//     return JSON.stringify(Object.fromEntries(requestMap));
// }
