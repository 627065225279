import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import "../../styles/components/HeadNav/HeadNavBar.css";
import { useState } from 'react';
import SignInButton from './SignInButton';

function HeadNavBar({ currentUser, setCurrentUser }) {
    const [activeItem, setActiveItem] = useState('backtest');

    const handleItemClick = (item) => {
        setActiveItem(item);
    };

    return (
        <Navbar bg="dark" variant="dark" collapseOnSelect expand="lg">
            <Navbar.Brand className='logo-font'>
                CHAPATY<span className='ai-font'>AI</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id='responsive-navbar-nav'>
                <Nav className="me-auto">
                    <Nav.Link
                        className={activeItem === 'backtest' ? 'text-bold' : ''}
                        href="#home" active={activeItem === 'backtest'}
                        onClick={() => handleItemClick('backtest')}>
                        Backtest
                    </Nav.Link>
                    <Nav.Link
                        className={activeItem === 'custom' ? 'text-bold' : ''}
                        href="#link" active={activeItem === 'custom'}
                        onClick={() => handleItemClick('custom')}>
                        Custom
                    </Nav.Link>
                    <Nav.Link
                        className={activeItem === 'insights' ? 'text-bold' : ''}
                        href="#link" active={activeItem === 'insights'}
                        onClick={() => handleItemClick('insights')}>
                        Insights
                    </Nav.Link>
                    <Nav.Link
                        className={activeItem === 'Pricing' ? 'text-bold' : ''}
                        href="#link" active={activeItem === 'Pricing'}
                        onClick={() => handleItemClick('Pricing')}>
                        Pricing
                    </Nav.Link>
                </Nav>
                <SignInButton
                    currentUser={currentUser}
                    setCurrentUser={setCurrentUser}
                    text={"Sign In"}
                />
            </Navbar.Collapse>
        </Navbar>
    );
}

export default HeadNavBar;