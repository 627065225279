import { ListGroup } from "react-bootstrap";
import { useState } from "react";
import StrategySection from "./StrategySection";
import SavedBot from "./SavedBot";

export default function SavedBotsList({
    strategies,
    strategy,
    currentUser,
    botRefs,
    setBotRefs,
    botName,
    setBotName,
    setBacktestResponse,
    setBacktestRequest,
    setText,
    activeItemId,
    setActiveItemId,
    latestRequestId,
    setLatestRequestId,
    currentMarket,
    setCurrentMarket,
    currentYear,
    setCurrentYear,
}) {

    const [activeRenameId, setActiveRenameId] = useState(null);
    const [activeConfirmationId, setActiveConfirmationId] = useState(null);
    const [sections, setSections] = useState({
        [strategies[0]]: false,
        [strategies[1]]: false,
        [strategies[2]]: false,
        [strategies[3]]: false,
        [strategies[4]]: false,
        [strategies[5]]: false,
    });


    return (
        <ListGroup.Item key={strategy} className="saved-bots-list">
            <StrategySection strategy={strategy} sections={sections} setSections={setSections} />
            {currentUser && sections[strategy] && (
                <>
                    {
                        botRefs[strategy] && botRefs[strategy].map(([id, name, isAi]) => {
                            const isActiveItem = id === activeItemId;
                            const confirmationId = id === activeConfirmationId;
                            const renameId = id === activeRenameId;

                            return (
                                <SavedBot
                                    isActiveItem={isActiveItem}
                                    confirmationId={confirmationId}
                                    renameId={renameId}
                                    currentUser={currentUser}
                                    id={id}
                                    name={name}
                                    isAi={isAi}
                                    botName={botName}
                                    setBotName={setBotName}
                                    setBacktestRequest={setBacktestRequest}
                                    setBacktestResponse={setBacktestResponse}
                                    setText={setText}
                                    strategy={strategy}
                                    botRefs={botRefs}
                                    setBotRefs={setBotRefs}
                                    setActiveRenameId={setActiveRenameId}
                                    setActiveConfirmationId={setActiveConfirmationId}
                                    setActiveItemId={setActiveItemId}
                                    latestRequestId={latestRequestId}
                                    setLatestRequestId={setLatestRequestId}
                                    setCurrentMarket={setCurrentMarket}
                                    setCurrentYear={setCurrentYear}
                                />);
                        })
                    }
                </>
            )}
        </ListGroup.Item>
    );
}
