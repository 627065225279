import "../styles/components/Footer.css";
export default function Footer() {
    return (
        <footer class="footer">
            <hr class="footer-line" />
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <p class="text-center">
                            <i class="fas fa-circle"></i>
                            <span class="copyright">
                                COPYRIGHT LEN CEWA WILLIAMSON &copy; 2023 CHAPATY AI  <i className="bi bi-github"></i>
                            </span>
                        </p>
                    </div>
                    <div class="col-md-6">
                        <p class="text-center">
                            <a href="#" class="about-link">Impressum & Datenschutz</a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>

    )
}