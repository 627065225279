import { Form, Offcanvas, Table } from "react-bootstrap";

export default function BotSettings({ backtestRequest, show, onHide, text, setText }) {

    return (
        <Offcanvas show={show} onHide={onHide} scroll={true} backdrop={false}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title><strong>{typeof backtestRequest['strategy'] === 'undefined' ? 'No Data. Please create New Bot' : backtestRequest['strategy'].toUpperCase()}</strong></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>Stop Loss</th>
                            <th>Take Profit</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{typeof backtestRequest['stop_loss'] === 'undefined' ? 'Not set' :
                                backtestRequest['stop_loss']['kind']
                            }
                            </td>
                            <td>{typeof backtestRequest['take_profit'] === 'undefined' ? 'Not set' :
                                backtestRequest['take_profit']['kind']
                            }</td>
                        </tr>
                        <tr>
                            <td>{typeof backtestRequest['stop_loss'] === 'undefined' ? 'Not set' :
                                backtestRequest['stop_loss']['offset']
                            }
                            </td>
                            <td>{typeof backtestRequest['take_profit'] === 'undefined' ? 'Not set' :
                                backtestRequest['take_profit']['offset']
                            }</td>
                        </tr>
                    </tbody>
                </Table>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Notes</Form.Label>
                    <Form.Control as="textarea" rows={20} value={text} onChange={(e) =>  setText(e.target.value)} />
                </Form.Group>
            </Offcanvas.Body>
        </Offcanvas>
    );
}