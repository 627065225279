import { ListGroup, Row, Col, Button } from 'react-bootstrap';
import '../styles/views/GettingStarted.css';

export default function GettingStarted() {
    // https://mdbootstrap.com/docs/react/extended/timeline/
    return (
        <Row className='getting-started'>

            <Col>
                <div className='getting-started-ad'>
                    <h1>Uncover Hidden Profits</h1>
                    <p>
                        <h3>
                            Backtest Your Strategies
                        </h3>
                    </p>
                    <p>
                        <h5>
                            Leveraging Our Open Source Software CHAPATY AI!
                        </h5>
                    </p>
                    <p>
                        Get started in just 4 steps and get your results in less than 1 minute!
                    </p>

                    <ListGroup as="ol" numbered variant='flush' >
                        <ListGroup.Item as="li">Sign In</ListGroup.Item>
                        <ListGroup.Item as="li">Create New Bot</ListGroup.Item>
                        <ListGroup.Item as="li">Backtest Bot</ListGroup.Item>
                        <ListGroup.Item as="li">Analyse Performance</ListGroup.Item>
                    </ListGroup>

                    {/* <div className='getting-started-button'>

                        <Button variant="dark">Get Started Now!</Button>
                    </div> */}
                </div>
            </Col>

        </Row>
    );
}