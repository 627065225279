import { Dropdown, Form } from "react-bootstrap";
import "../../styles/components/EquityCurve.css";

export default function EquityCurveDropDown({ activeElements, setActiveElements, setNumberActiveElements, currentActiveElement }) {
  function handleCheckboxChange(e) {
    const element = e.target.value;
    const isChecked = e.target.checked;
    setNumberActiveElements((prevValue) => isChecked ? prevValue + 1 : prevValue - 1);
    setActiveElements((prevActiveElements) => {
      const updatedElements = prevActiveElements.map(([el, checked]) =>
        el === element ? [el, isChecked] : [el, checked]
      );
      return updatedElements;
    });
  }

  return (
    <Dropdown.Menu className="checkbox-dropdown">
      {
        activeElements
          .map(([element, isChecked]) => (
            <Form.Group className="dropdown-item" controlId={element} key={element}>
              <Form.Check
                inline
                label={element}
                value={element}
                id={`inline-${element}`}
                className="checkbox-margin-top"
                onChange={element === currentActiveElement ? null : handleCheckboxChange}
                checked={isChecked}
              />
            </Form.Group>
          ))
      }
    </Dropdown.Menu>
  );
}
