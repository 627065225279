import { getAuth, onAuthStateChanged } from "firebase/auth";

export function isUserSignedIn() {
    const auth = getAuth();
    return new Promise((resolve) => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in
          const uid = user.uid;
          resolve(true);
        } else {
          // User is signed out
          resolve(false);
        }
      });
    });
}