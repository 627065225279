import { getPerformanceReport, getPnL, getTradeBreakdownReport } from "./backtestResponseQuery";

export function downloadCSV(data, key, year, market) {
    var jsonToDownload;
    switch (key) {
        case 'trade_breakdown_report': jsonToDownload = getTradeBreakdownReport(data, market, year);
        case 'performance_report': jsonToDownload = getPerformanceReport(data, market, year);
        case 'pnl_statement': jsonToDownload = getPnL(data, market, year);
    }

    const csvData = convertJsonToCsv(jsonToDownload, year);

    // Create a Blob object
    const blob = new Blob([csvData], { type: 'text/csv' });

    // Create a download link
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = year ? `${key}_${year}.csv` : `${key}.csv`;

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up
    URL.revokeObjectURL(link.href);
}

function convertJsonToCsv(jsonData, year) {
    // jsonData = year ? profitAndLossDataInYear(jsonData, year) : jsonData;
    const headers = Object.keys(jsonData);
    const dataLength = jsonData[headers[0]].length;
    const rows = [...Array(dataLength).keys()].map(i => fetchRow(i, headers, jsonData));

    return [
        headers.join(','),
        ...rows.map((row) => row.join(','))
    ].join('\n');
}

function fetchRow(i, headers, jsonData) {
    return headers.reduce((row, key) => {
        row.push(jsonData[key][i]);
        return row;
    }, []);
}

function profitAndLossDataInYear(jsonData, currentYear) {
    return jsonData.filter(obj => {
        const date = new Date(obj['date'][0]);
        return date.getFullYear() === currentYear;
    })[0];
}
