import array from 'lodash/array';

export function sortTableByKey(data, key, isAsc, hasUidCol) {
  const idColumn = hasUidCol ? data['uid'].slice() : data['id'].slice();
  const columnToSort = data[key].slice();
  const columnToSortWithIds = array.zip(idColumn, columnToSort);
  const sorted = columnToSortWithIds.sort(([, a], [, b]) => {
    if (typeof a === 'string' && typeof b === 'string') {
      // Compare strings lexicographically
      return sortString(isAsc, a, b);
    } else {
      return isAsc ? a - b : b - a;
    }
  });

  const acc = {};
  Object.keys(data).forEach(key => {
    acc[key] = [];
  });

  const resultJson = sorted.reduce((result, [id, _]) => {
    Object.keys(data).forEach((_key) => {
      result[_key].push(data[_key][id - 1]);
    });
    return result;
  }, acc);

  return resultJson;
}

export function sortString(isAsc, a, b) {
  return isAsc ? a.localeCompare(b) : b.localeCompare(a);
}