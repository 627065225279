import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';


export default function EquityCurve({ data }) {

    const dataToPlot = data.map((equity, index) => {
        return {
            name: index,
            id: index,
            profit: Math.round(equity),
        };
    });

    const gradientOffset = () => {
        const initialPortfolio = data[0];
        const dataMax = Math.max(...dataToPlot.map((i) => i.profit));
        const dataMin = Math.min(...dataToPlot.map((i) => i.profit));

        if (dataMax <= initialPortfolio) {
            return 0;
        }
        if (dataMin >= initialPortfolio) {
            return 1;
        }

        return 1 - (initialPortfolio - dataMin) / (dataMax - dataMin);
    };

    const off = gradientOffset();


    return (
        <ResponsiveContainer width="100%" height={500}>
            <AreaChart
                width={500}
                height={300}
                data={dataToPlot}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 15,
                }}
            >

                <CartesianGrid strokeDasharray="3 3" />
                <XAxis interval={99} dataKey="name">
                    <Label value="Trade #Id" offset={-10} position="insideBottom" />
                </XAxis>
                <YAxis>
                    <Label value="USD" angle={-90} position="left" offset={10} textAnchor="middle" />
                </YAxis>
                <Tooltip labelFormatter={(label) => `#Id: ${label}`} />
                <defs>
                    <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                        <stop offset={off} stopColor="#abb4f7" stopOpacity={1} />
                        <stop offset={off} stopColor="#605aa0" stopOpacity={1} />
                    </linearGradient>
                </defs>
                <Area type="monotone" dataKey="profit" name="Net Profit" stroke="#808080" fill="url(#splitColor)" activeDot={{ r: 8 }} opacity={1} />
            </AreaChart>
        </ResponsiveContainer>
    );
}