import { Button, Dropdown } from "react-bootstrap";
import "../../styles/components/HeadNav/SignInButton.css";
import React, { useEffect, useState } from "react";
import { signInUser } from "../../utils/firebase/signIn";
import { isUserSignedIn } from "../../utils/firebase/common";
import { signOutUser } from "../../utils/firebase/signOut";
import { getAuth } from "firebase/auth";



export default function SignInButton({ currentUser, setCurrentUser, text }) {
    const [buttonText, setButtonText] = useState(text);

    async function handleSignIn() {
        const signedIn = await isUserSignedIn();
        if (!signedIn) {
            const user = await signInUser();
            setCurrentUser(user);
        }
    }

    async function handleSignOut() {
        const signedIn = await isUserSignedIn();
        if (signedIn) {
            await signOutUser();
            const auth = getAuth();
            const user = auth.currentUser;
            setCurrentUser(user);
        }
    }

    useEffect(() => {
        if (currentUser) {
            setButtonText("Sign Out");
        } else {
            setButtonText(text);
        }
    }, [currentUser]);

    return (
        <div>
            {
                currentUser ?
                    (
                        <div className="free-trial-container">
                            <span className="separator"><strong className="trial-text">Free Trials: 10</strong></span>
                            <AvatarDropdown letter={currentUser.displayName.charAt(0).toUpperCase()} handleSignOut={handleSignOut} />
                        </div>
                    )
                    :
                    (<Button variant="outline-light" className='sign-in-button' onClick={handleSignIn}>
                        <i class="bi bi-person-circle" />
                        <span className='sign-in'>{buttonText}</span>
                    </Button>)
            }
        </div>
    );
}

function AvatarDropdown({ letter, handleSignOut }) {
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div
            href=""
            className="avatar avatar-toggle"
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
        </div>
    ));
    return (
        <Dropdown drop='start'>
            <Dropdown.Toggle variant="light" as={CustomToggle}>
                {letter}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item><i class="bi bi-box-arrow-up-right" style={{ marginRight: '8px' }} />Help</Dropdown.Item>
                <Dropdown.Item><i class="bi bi-gear" style={{ marginRight: '8px' }} />Settings</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleSignOut}><i class="bi bi-box-arrow-right" style={{ marginRight: '8px' }} />Sign Out</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}