import { Card } from "react-bootstrap";
import "../../styles/components/Kpi.css";
import NumberIncrementer from "../NumberIncrementer";

export default function NetProfit({ netProfit }) {

  return (
    <Card style={{ width: '18rem' }} className='mb-2 card'>
      <Card.Header>Net Profit</Card.Header>
      <Card.Body>
        <Card.Title>
          <h1 className={netProfit < 0 ? "color-loss" : "color-win"}>
            <i class="bi bi-currency-dollar" />
            <strong>{
              <NumberIncrementer target={netProfit} intervalTime={10} stepSize={50} />
            }</strong>
          </h1>
        </Card.Title>
        <Card.Text>
          <span className={netProfit < 0 ? "color-loss" : "color-win"}><i className={`${netProfit < 0 ? "bi bi-chevron-compact-down" : "bi bi-chevron-compact-up"}`} /> {((netProfit / 10000) * 100).toFixed(0)}%</span> yield on an inital investment of 10k USD
        </Card.Text>
      </Card.Body></Card>
  );
}