import React, { useState, useEffect } from 'react';

function NumberIncrementer({ target, intervalTime, stepSize }) {
    const [value, setValue] = useState(0);
    const negativeProfit = target < 0 ? true : false;
    target = target < 0 ? (-1) * target : target;
    useEffect(() => {
        const timer = setInterval(() => {
            setValue((prevValue) => {
                if (prevValue >= target) {
                    clearInterval(timer);
                    return target;
                }
                return prevValue + stepSize;
            });
        }, intervalTime); // Adjust the interval time as needed

        return () => {
            clearInterval(timer); // Clean up the timer on unmount
        };
    }, [target]);
    return <>{
        parseFloat(
            (negativeProfit ? (-1) * value : value).toFixed(0)
        )
    }</>;
}

export default NumberIncrementer;
