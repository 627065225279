import { Table, Row, Col } from 'react-bootstrap';
import './../../styles/components/Reports.css';
import { sortTableByKey } from '../../utils/common/sorting';
import { useEffect, useState } from 'react';

export default function PerformanceReportTable({ data, activeYear }) {
  const [tableContent, setTableContent] = useState(data);
  const [sortedHeader, setSortedHeader] = useState('year');
  const [maxDrawDownCalls, setMaxDrawDownCalls] = useState(1);
  const [isAsc, setIsAsc] = useState(false);

  function toggleSort(key) {
    if (key === 'max_draw_down_abs') {
      if (3 <= maxDrawDownCalls && maxDrawDownCalls <= 4) {
        // negate isAsc due to negativ sign (mathematically wrong, but what the normal user expects)
        setTableContent(sortTableByKey(data, 'max_draw_down_rel', !isAsc, false));
      } else {
        setTableContent(sortTableByKey(data, key, isAsc, false));
      }
      setMaxDrawDownCalls((maxDrawDownCalls % 4) + 1);

    } else {
      if (key === 'avg_loss') {
        // negate isAsc due to negativ sign (mathematically wrong, but what the normal user expects)
        setTableContent(sortTableByKey(data, key, !isAsc, false));
      } else {
        setTableContent(sortTableByKey(data, key, isAsc, false));
      }
      setMaxDrawDownCalls(1);
    }
    setIsAsc(!isAsc);
    setSortedHeader(key);
  }

  useEffect(() => {
    setTableContent(data);
  }, [data]);

  const rows = tableContent.year.map((year, idx) => {
    const isHighlighted = year === activeYear;
    return (
      <tr className={`table-format ${isHighlighted ? 'highlighted-row' : ''}`} key={idx}>
        <td>{year}</td>
        <td>{tableContent.net_profit[idx].toFixed(2)}</td>
        <td>{tableContent.avg_win_by_trade[idx].toFixed(2)}</td>
        <td>
          <Row >
            <Col>
              {tableContent.max_draw_down_abs[idx].toFixed(2)}
            </Col>
            <Col>
              <span className='draw-down-rel'>
                <i class="bi bi-chevron-compact-down" />{(tableContent.max_draw_down_rel[idx] * 100).toFixed(0)}%
              </span>
            </Col>
          </Row>
        </td>
        <td>{tableContent.percentage_profitability[idx].toFixed(3)}</td>
        <td>{tableContent.ratio_avg_win_by_avg_loss[idx] === null ? <i class="bi bi-infinity" /> : tableContent.ratio_avg_win_by_avg_loss[idx].toFixed(3)}</td>
        <td>{tableContent.avg_win[idx].toFixed(2)}</td>
        <td>{tableContent.avg_loss[idx].toFixed(2)}</td>
        <td>{tableContent.profit_factor[idx] === null ? <i class="bi bi-infinity" /> : tableContent.profit_factor[idx].toFixed(2)}</td>
      </tr>
    );
  });

  const headerMap = {
    'year': 'Year',
    'net_profit': 'Net Profit',
    'avg_win_by_trade': 'ø Win by Trade',
    'max_draw_down_abs': 'Maximum Draw Down',
    'percentage_profitability': '%-Profitability',
    'ratio_avg_win_by_avg_loss': 'ø-Win / ø-Loss',
    'avg_win': 'ø-Win',
    'avg_loss': 'ø-Loss',
    'profit_factor': 'Profit Factor'
  };

  return (
    <div className='table-responsive table-features'>
      <Table hover bordered style={{ whiteSpace: "nowrap" }}>
        <thead className='table-header'>
          <tr className='table-format'>
            {
              Object.entries(headerMap).map(([key, title]) => {
                return (
                  <th onClick={() => toggleSort(key)}>
                    <span className='table-header-element'>
                      {title}
                      {key === sortedHeader ?
                        <div className={isAsc ? 'arrow-rotate-back' : 'arrow-rotate'}>
                          <i class="bi bi-arrow-down" />
                        </div>
                        :
                        null
                      }
                    </span>
                  </th>
                );
              })
            }
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </div>
  );
}
