import { format } from 'date-fns';
import { ScatterChart, Scatter, XAxis, YAxis, Label, CartesianGrid, CartesianAxis, Tooltip, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';
export default function ProfitsByDate({ data }) {
    const dates = data['date'];
    const pls = data['pl_dollar'];
    const dataToPlot = dates.map((date, idx) => ({
        'date': getEpoch(date),
        'pnl': pls[idx],
        'isSuspicious': isSuspiciousTrade(data.entry_timestamp[idx], data.take_profit_timestamp[idx], data.stop_loss_timestamp[idx])
    }));

    const sortData = (a, b) => {
        // Sort by isSuspicious property (true comes after false)
        return a.isSuspicious - b.isSuspicious;
    };
    const sortedDataToPlot = [...dataToPlot].sort(sortData);

    
    const formatTooltipLabel = (value, name) => {
        if (name === 'date') {
            const date = new Date(value);
            const formattedDate = format(date, 'yyyy-MM-dd');
            return formattedDate;
        } else {
            return value;
        }
    };

    return (
        <ResponsiveContainer width="100%" height={400}>
            <ScatterChart
                margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                }}
            >
                <CartesianAxis />
                <XAxis type="number" scale='time' dataKey="date" name="date" hide={true} tickFormatter={formatDateTick} domain={['auto', 'auto']} />
                <YAxis type="number" dataKey="pnl" name="PnL">
                    <Label value="USD" angle={-90} position="left" offset={10} textAnchor="middle" />
                </YAxis>
                <Tooltip cursor={{ strokeDasharray: '3 3' }} formatter={formatTooltipLabel} content={<CustomTooltip />}/>
                <Scatter data={sortedDataToPlot} fill="#8884d8" shape={<CustomizedShape />} />
            </ScatterChart>
        </ResponsiveContainer>
    );
}

function getEpoch(dateString) {
    const date = new Date(dateString);
    return date.getTime();
}

function isSuspiciousTrade(entry_ts, tp_ts, sl_ts) {
    if (entry_ts === 'NoEntry' || (tp_ts === 'Timeout' && sl_ts === 'Timeout')) {
        return false;
    }
    if (entry_ts === tp_ts || entry_ts === sl_ts || tp_ts === sl_ts) {
        return true;
    }
    return false
}

const formatDateTick = (timestamp) => {
    const date = new Date(timestamp);
    return format(date, 'yyyy-MM-dd');
};

const CustomizedShape = ({ cx, cy, isSuspicious }) => {
    const radius = 5; // Adjust the radius as needed
    const fillColor = isSuspicious ? '#FF0000' : '#8884d8'; // Use red for suspicious, and blue for non-suspicious
    const shapePath = isSuspicious ? 'circle' : 'circle'; // Use a circle for suspicious, and a cross for non-suspicious

    return (
        <g>
            <path
                d={shapePath === 'circle' ? `M${cx},${cy}m-${radius},0a${radius},${radius} 0 1,0 ${radius * 2},0a${radius},${radius} 0 1,0-${radius * 2},0` : ''}
                fill={fillColor}
            />
        </g>
    );
};

CustomizedShape.propTypes = {
    cx: PropTypes.number,
    cy: PropTypes.number,
    isSuspicious: PropTypes.bool,
};

const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      const suspiciousCount = payload[0] ? payload[0].payload.isSuspicious : 0;
      return (
        <div className="custom-tooltip">
          <p>Date: {formatDateTick(payload[0].value)}</p>
          <p>PnL: {payload[0].payload.pnl}</p>
          <p>Suspicious Trade: {payload[0].payload.isSuspicious.toString()}</p>
        </div>
      );
    }
    return null;
  };

  /**
   * März 2022 Offstet: 0.01965 
   * 
   */