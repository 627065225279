
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

import "../styles/components/WinnerLoser.css";

function CustomTooltip({ active, payload }) {
    if (active && payload && payload.length) {
        const label = payload[0].name;
        return (
            <div className="custom-tooltip">
                <p className="label">{`${label} : ${payload[0].value}`}</p>
            </div>
        );
    }
    return null;
};

export default function WinnerLoser({ data, year }) {
    const currentYearIndex = year === 'All Years' ? 0 : data['year'].indexOf(year);

    const data01 = [
        { name: 'All Winner', value: data['total_number_winner_trades'][currentYearIndex] },
        { name: 'All Loser', value: data['total_number_loser_trades'][currentYearIndex] },
        { name: 'No Entry', value: data['number_no_entry'][currentYearIndex] },
    ];
    const data02 = [
        { name: 'Winner', value: data['number_winner_trades'][currentYearIndex] },
        { name: 'Timeout Winner', value: data['number_timeout_winner_trades'][currentYearIndex] },
        { name: 'Loser', value: data['number_loser_trades'][currentYearIndex] },
        { name: 'Timeout Loser', value: data['number_timeout_loser_trades'][currentYearIndex] },
        { name: 'No Entry', value: data['number_no_entry'][currentYearIndex] },
    ];

    // green #00C49F
    // red #FF8042
    // timeout #808080
    // blue #0088FE

    return (
        <ResponsiveContainer width="99%" height={250}>
            <PieChart>
                <Pie data={data01} dataKey="value" cx="50%" cy="50%" outerRadius={60} fill="#8884d8" isAnimationActive={true}>
                    {
                        data01.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={
                                entry.name === 'No Entry' ? '#8A8AFF' :
                                    entry.name === 'All Winner' ? '#abb4f7' :
                                        entry.name === 'All Loser' ? '#605aa0' :
                                            '#8884d8'
                            } />
                        ))
                    }
                </Pie>
                <Pie data={data02} dataKey="value" cx="50%" cy="50%" innerRadius={70} outerRadius={90} fill="#abb4f7" label >
                    {
                        data02.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={
                                entry.name === 'No Entry' ? '#8A8AFF' :
                                    entry.name === 'Winner' ? '#abb4f7' :
                                        entry.name === 'Loser' ? '#605aa0' :
                                            entry.name === 'Timeout Winner' || entry.name === 'Timeout Loser' ? "#808080" :
                                                '#8884d8'
                            } />
                        ))
                    }
                </Pie>
                <Tooltip content={<CustomTooltip />} />
            </PieChart>
        </ResponsiveContainer>
    );
}