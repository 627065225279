
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import HeadNavBar from './components/HeadNav/HeadNavBar';
import Backtest from './views/Backtest';
import SideNavBar from './components/SideNav/SideNavBar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from './components/Footer';
import { useEffect, useState } from 'react';
import GettingStarted from './views/GettingStarted';

import { loadLatestRequest } from './utils/firebase/firestore';

import pnlStatement from './mockData/backtestResponse/marketAndYearBacktestResult/pnlStatement.json';
import performanceReport from './mockData/backtestResponse/marketAndYearBacktestResult/performanceReports.json';
import tradeBreakdownReport from './mockData/backtestResponse/marketAndYearBacktestResult/tradeBreakdownReports.json';
import equityCurvesReport from './mockData/backtestResponse/marketAndYearBacktestResult/equityCurvesReport.json';
import pnlStatementAggYears from './mockData/backtestResponse/marketAndAggYearsBacktestResult/pnlStatementAggYears.json';
import performanceReportAggYears from './mockData/backtestResponse/marketAndAggYearsBacktestResult/performanceReportsAggYears.json';
import tradeBreakdownReportAggYears from './mockData/backtestResponse/marketAndAggYearsBacktestResult/tradeBreakdownReportsAggYears.json';
import equityCurvesAggYears from './mockData/backtestResponse/marketAndAggYearsBacktestResult/equityCurvesAggYears.json';
import pnlStatementAggMarkets from './mockData/backtestResponse/aggMarketsAndYearBacktestResult/pnlStatementAggMarkets.json';
import performanceReportAggMarkets from './mockData/backtestResponse/aggMarketsAndYearBacktestResult/performanceReportsAggMarkets.json';
import tradeBreakdownReportAggMarkets from './mockData/backtestResponse/aggMarketsAndYearBacktestResult/tradeBreakdownReportsAggMarkets.json';
import equityCurvesAggMarkets from './mockData/backtestResponse/aggMarketsAndYearBacktestResult/equityCurvesAggMarkets.json';
import pnlStatementAggMarketsAggYears from './mockData/backtestResponse/aggMarketsAndAggYearsBacktestResult/pnlStatementAggMarketsAggYears.json';
import performanceReportTotal from './mockData/backtestResponse/aggMarketsAndAggYearsBacktestResult/performanceReport.json';
import tradeBreakdownTotal from './mockData/backtestResponse/aggMarketsAndAggYearsBacktestResult/tradeBreakdownReport.json';
import equityCurve from './mockData/backtestResponse/aggMarketsAndAggYearsBacktestResult/equityCurve.json';

/**
 * Primary Colors CHARTS:
 * WIN: #abb4f7 (old: #82ca9d)
 * LOSS: #605aa0 (old: #FF5C5C)
 * NEUTRAL: #8884d8
 * GREY: 808080
 * 
 * Primary Colors Numbers:
 * green #00C49F
 * red #FF8042
 * timeout #808080
 * blue #0088FE
 * 
 * Firebase CLI: node_modules/.bin/firebase
 */

export default function App({ user }) {
  const mockResponse = getMockResponse();

  /**
   * Live nehmen & terraform & Stripe
   * Performance Bugs (GRPC, Document size all markets all years, Cloud Storage schmiert ab wenn ich alle märkrte über alle jahre anfrage)
   * Webserver LRU Cache um Ergebnisse zu cachen und die Anzahl der Abfragen zu reduzieren
   * CICD
   * Backend verbessern (mehrere Requests gleichzeitig -> kein Stack Overflow) => Release mode
   * SL: Relativ in % und Bugfix wenn SL > 0 => Low/High und TP = min(TP, SL)
   * Frontend aufräumen
   * Schulung: Docker & Kubernetes / GitHub Actions / GCP + Cloud Run + Terraform
   * 1. Daten exportieren 2022 - 2020 und prüfen ob die identisch sind
   * 2. Trade 6b 2022-12-15 anschauen (März Kontrakt 2023)
   * 3. Trade 6n 2022-12-15 anschauen (März Kontrakt 2023)
   * 4. Ich erkläre nochmal die Logik meiner Strategie am Chart andhand der beiden Trades
   * 5. Sicherstellen & verstehen wie wir beim Download historischer Daten die kontrakte richtig rollen
   * 6. Kleines Tutorial mit Bildern zum Downloaden zusammenschreiben (TODO Felix?), damit jeder das am Ende kann
   * 7. Rancho Dinero Indikator
   * 8. 19. Mai 2022 (gab es da news?) Trades für 6a, 6b, 6c, 6e, 6n angucken (auch März Kontrakt 2023) => Übersicht News
   * Franziska Kuhns
   */

  const [backtestResponse, setBacktestResponse] = useState(mockResponse);
  const [backtestRequest, setBacktestRequest] = useState('');
  const [text, setText] = useState('');
  const [activeItemId, setActiveItemId] = useState(null);
  const [currentUser, setCurrentUser] = useState(user);
  const [currentMarket, setCurrentMarket] = useState('6a');
  const [currentYear, setCurrentYear] = useState('2022');

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser) {
        const latestRequest = await loadLatestRequest(currentUser.uid);
        if (Object.keys(latestRequest).length > 0) {
          setBacktestRequest(latestRequest.request);
          setBacktestResponse(latestRequest.response);
          setText(latestRequest.text);
          setCurrentMarket(latestRequest.request['markets'][0]);
          setCurrentYear(latestRequest.request['years'][0].toString());
          // console.log(initialCurrentMarket, initialCurrentYear);
        }
      }
    };

    fetchData();
  }, [currentUser]);




  return (
    <Container fluid>
      <Row>
        <Col className='p-0'> <HeadNavBar
          currentUser={currentUser}
          setCurrentUser={setCurrentUser}
        /> </Col>
      </Row>
      <Row>
        <Col md={2} className='side-bar'>
          <SideNavBar
            setBacktestResponse={setBacktestResponse}
            setBacktestRequest={setBacktestRequest}
            setText={setText}
            activeItemId={activeItemId}
            setActiveItemId={setActiveItemId}
            currentUser={currentUser}
            setCurrentMarket={setCurrentMarket}
            setCurrentYear={setCurrentYear}
          />
        </Col>
        {currentUser ?
          <Col md={10}>
            <Backtest
              backtestResponse={backtestResponse}
              backtestRequest={backtestRequest}
              text={text}
              activeItemId={activeItemId}
              currentUser={currentUser}
              setText={setText}
              currentMarket={currentMarket}
              setCurrentMarket={setCurrentMarket}
              currentYear={currentYear}
              setCurrentYear={setCurrentYear}
            />
          </Col>
          :
          <Col md={10}><GettingStarted /></Col>}
      </Row>
      <Row>
        <Col className='p-0'><Footer /></Col>
      </Row>
    </Container>
  );
}



function getMockResponse() {
  const market_and_year = {
    pnl_statement: pnlStatement,
    performance_report: performanceReport,
    trade_breakdown_report: tradeBreakdownReport,
    equity_curve: equityCurvesReport,
  };
  const agg_market_and_year = {
    pnl_statement: pnlStatementAggMarkets,
    performance_report: performanceReportAggMarkets,
    trade_breakdown_report: tradeBreakdownReportAggMarkets,
    equity_curve: equityCurvesAggMarkets
  };
  const market_and_agg_year = {
    pnl_statement: pnlStatementAggYears,
    performance_report: performanceReportAggYears,
    trade_breakdown_report: tradeBreakdownReportAggYears,
    equity_curve: equityCurvesAggYears
  };
  const agg_market_and_agg_year = {
    pnl_statement: pnlStatementAggMarketsAggYears,
    performance_report: performanceReportTotal,
    trade_breakdown_report: tradeBreakdownTotal,
    equity_curve: equityCurve
  };
  const mockResponse = {
    market_and_year,
    agg_market_and_year,
    market_and_agg_year,
    agg_market_and_agg_year,
  };
  return mockResponse;
}





