import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
  Label
} from 'recharts';



export default function TotalWinLoss({ data, year }) {
  const currentYearIndex = year === 'All Years' ? 0 : data['year'].indexOf(year);

  const dataToPlot = [
    {
      name: 'Total Win',
      cleanWinner: data['clean_win'][currentYearIndex].toFixed(0),
      timeoutWinner: data['timeout_win'][currentYearIndex].toFixed(0),

    },
    {
      name: 'Total Loss',
      cleanLoser: data['clean_loss'][currentYearIndex].toFixed(0),
      timeoutLoser: data['timeout_loss'][currentYearIndex].toFixed(0),
    },
  ];

  return (
    <ResponsiveContainer width="99%" height={250}>
      <BarChart
        data={dataToPlot}
        stackOffset="sign"
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 15,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis>
          <Label value="USD" angle={-90} position="left" textAnchor="middle" />
        </YAxis>
        <Tooltip labelFormatter={(label) => `${label} [USD]`}/>
        <ReferenceLine y={0} stroke="#000" />
        <Bar dataKey="cleanWinner" name='Regular' fill="#abb4f7" stackId="stack" />
        <Bar dataKey="cleanLoser" name='Regular' fill="#605aa0" stackId="stack" />
        <Bar dataKey="timeoutWinner" name='Timeout' fill="#808080" stackId="stack" />
        <Bar dataKey="timeoutLoser" name='Timeout' fill="#808080" stackId="stack" />
      </BarChart>
    </ResponsiveContainer>
  );
}