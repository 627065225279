import { Button, Row, Col, ListGroup } from "react-bootstrap";
import "../../styles/components/SideNav/SideNavBar.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import NewBot from "./NewBot";
import { getLatestRequest, loadRequestHistory } from "../../utils/firebase/firestore";
import SavedBotsList from "./SavedBotsList";
import { useState, useEffect } from "react";

export default function SideNavBar(props) {
    const strategies = ['PPP', 'MAGNETO', 'POST NEWS'];

    const [modalShow, setModalShow] = useState(false);
    const [botName, setBotName] = useState('');
    const [botRefs, setBotRefs] = useState({});
    const [latestRequestId, setLatestRequestId] = useState(null);

    useEffect(() => {
        if (props.currentUser) {
            loadRequestHistory(props.currentUser.uid, strategies).then(requestHistory => {
                setBotRefs(requestHistory);
            });
            getLatestRequest(props.currentUser.uid).then(latestRequest => {
                props.setActiveItemId(latestRequest.id);
                setLatestRequestId(latestRequest.id);
            });
        }
    }, [props.currentUser]);

    return (
        <>
            <Row>
                <Col className="add-bot">
                    <Button className="w-100" variant='outline-dark' onClick={() => setModalShow(true)} disabled={props.currentUser ? false : true}>
                        <span class="bi bi-plus"> New Bot</span>
                    </Button>
                    <NewBot
                        show={modalShow}
                        setBacktestResponse={props.setBacktestResponse}
                        setBacktestRequest={props.setBacktestRequest}
                        onHide={() => setModalShow(false)}
                        currentUser={props.currentUser}
                        botName={botName}
                        setBotName={setBotName}
                        setBotRefs={setBotRefs}
                        setText={props.setText}
                        setActiveItemId={props.setActiveItemId}
                        setLatestRequestId={setLatestRequestId}
                        setCurrentMarket={props.setCurrentMarket}
                        setCurrentYear={props.setCurrentYear}
                    />
                </Col>
            </Row>
            <Row style={{ borderTop: '1px solid #343a40' }}>
                <Col style={{ marginTop: '10px' }}>
                    <ListGroup variant="flush">
                        {strategies.map((strategy) => {
                            return (
                                <SavedBotsList
                                    strategies={strategies}
                                    strategy={strategy}
                                    currentUser={props.currentUser}
                                    botRefs={botRefs}
                                    setBotRefs={setBotRefs}
                                    botName={botName}
                                    setBotName={setBotName}
                                    setBacktestResponse={props.setBacktestResponse}
                                    setBacktestRequest={props.setBacktestRequest}
                                    setText={props.setText}
                                    activeItemId={props.activeItemId}
                                    setActiveItemId={props.setActiveItemId}
                                    latestRequestId={latestRequestId}
                                    setLatestRequestId={setLatestRequestId}
                                    setCurrentMarket={props.setCurrentMarket}
                                    setCurrentYear={props.setCurrentYear}
                                />
                            );
                        })}
                    </ListGroup>

                </Col>
            </Row>
        </>
    );
}