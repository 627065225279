import { Card } from "react-bootstrap";
import NumberIncrementer from "../NumberIncrementer";

export default function AvgWinByTrade({ avgWin }) {
  return (
    <Card style={{ width: '18rem' }} className='mb-2'>
      <Card.Header>
        Average Win by Trade
      </Card.Header>
      <Card.Body>
        <Card.Title>
          <h1 className={avgWin < 5 ? "color-loss" : "color-win"}>
            <i class="bi bi-trophy distance-icon-kpi" />
            <strong><NumberIncrementer target={avgWin} intervalTime={10} stepSize={1} /></strong>
          </h1>
        </Card.Title>
        <Card.Text>
          <i className={`${avgWin < 5 ? "bi bi-x-lg color-loss" : "bi bi-check2-all color-win"}`} /> trades result in a {avgWin < 5 ? 'loss' : 'profit'} that {avgWin < 5 ? 'do not' : ''} cover the cost of trading
        </Card.Text>
      </Card.Body></Card>
  );
}