import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { addUser, decrementRemainingTrials, isNewUser } from "./firestore";

export async function signInUser() {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    return await signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            isNewUser(user.uid)
                .then((result) => {
                    if (result) {
                        addUser(user);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            return user;
        }).catch((error) => {
            // // Handle Errors here.
            // const errorCode = error.code;
            // const errorMessage = error.message;
            // // The email of the user's account used.
            // const email = error.customData.email;
            // // The AuthCredential type that was used.
            // const credential = GoogleAuthProvider.credentialFromError(error);
            // // ...

            console.log('Sign not successfull, returning null user', error);
            return null;
        });
}
