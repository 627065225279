import { Card } from "react-bootstrap";
import NumberIncrementer from "../NumberIncrementer";

export default function MaxDrawDown({maxDrawDownAbs, maxDrawDownRel}) {
  return (
    <Card style={{ width: '18rem' }} className='mb-2'>
      <Card.Header>Maximum Draw Down</Card.Header>
      <Card.Body>
        <Card.Title>
          <h1 className="color-loss">
            <i class="bi bi-graph-down-arrow distance-icon-kpi" />
            <strong><NumberIncrementer target={maxDrawDownAbs} intervalTime={10} stepSize={50}/></strong>
          </h1>
        </Card.Title>
        <Card.Text>
          <span className="color-loss"><i class="bi bi-chevron-compact-down" />{(maxDrawDownRel * 100).toFixed(0)}%</span> decrease in your returns
        </Card.Text>
      </Card.Body></Card>
  );
}