import { deleteBacktestDocument, loadData, renameBot, setLatestRequest } from "../../utils/firebase/firestore";
import { ListGroup, Form } from "react-bootstrap";

export default function SavedBot({
    isActiveItem,
    confirmationId,
    renameId,
    currentUser,
    id,
    name,
    isAi,
    botName,
    setBotName,
    setBacktestRequest,
    setBacktestResponse,
    setText,
    strategy,
    botRefs,
    setBotRefs,
    setActiveRenameId,
    setActiveConfirmationId,
    setActiveItemId,
    latestRequestId,
    setLatestRequestId,
    setCurrentMarket,
    setCurrentYear
}) {
    const isLatestRequest = id === latestRequestId;

    async function updateData(itemId) {
        const data = await loadData(currentUser.uid, strategy.toLowerCase(), itemId);
        if (Object.keys(data).length > 0) {
            setBacktestRequest(data['request']);
            setBacktestResponse(data['response']);
            setCurrentMarket(data['request']['markets'][0]);
            setCurrentYear(data['request']['years'][0].toString());
            setText(data['text'])
        }
    }

    function handleItemClick(itemId) {
        setActiveItemId(itemId);
        updateData(itemId);
    }

    const handleInputChange = (e) => {
        const enteredName = e.target.value;
        setBotName(enteredName);
    };

    function handleRenameConfirm(e, id) {
        e.stopPropagation();
        setActiveConfirmationId(null);
        setActiveRenameId(null);
        renameBot(currentUser.uid, strategy.toLowerCase(), id, botName);
        setBotRefs((prevBotRefs) => ({
            ...prevBotRefs,
            [strategy]: prevBotRefs[strategy].map((botRef) => {
                if (botRef[0] === id) {
                    return [botRef[0], botName, botRef[2]];
                }
                return botRef;
            })
        }));
    }

    function handleDeleteConfirm(e, id) {
        e.stopPropagation();
        setActiveConfirmationId(null);

        // Remove from botRefs
        setBotRefs((prevBotRefs) => ({
            ...prevBotRefs,
            [strategy]: prevBotRefs[strategy].filter((botRef) => botRef[0] !== id)
        }));

        // Remove in Backend
        deleteBacktestDocument(currentUser.uid, strategy.toLowerCase(), id);
        const newId = isLatestRequest || isActiveItem ? determineNewId(id) : id;
        console.log(newId, isActiveItem, isLatestRequest);

        if (isActiveItem) {
            // Set new activeItem, because we delete the current active Item
            setActiveItemId(newId);
            updateData(newId);
        }

        if (isLatestRequest) {
            setLatestRequest(currentUser.uid, newId, strategy);
            setLatestRequestId(newId);
        }
    }

    function determineNewId(id) {
        if (botRefs[strategy].length > 1) {
            return botRefs[strategy][0][0] === id ? botRefs[strategy][1][0] : botRefs[strategy][0][0];
        } else {
            return '';
        }
    }

    function handleRenameOrDelete(e, id, isRename) {
        // Don't make this item active, when renaming or deleting
        e.stopPropagation();
        setActiveConfirmationId(id);
        if (isRename) {
            setActiveRenameId(id);
        }
    }

    function handleCancel(e, renameId) {
        e.stopPropagation();
        setActiveConfirmationId(null);
        if (renameId) {
            setActiveRenameId(null);
        }
    }

    return (
        <ListGroup.Item
            className={`saved-bots ${isActiveItem ? 'active-item' : ''}`}
            key={id}
            onClick={isActiveItem ? undefined : () => handleItemClick(id)}
            active={isActiveItem}
        >
            <div className="ai-bot" >
                <div className="align-ai-bot" >
                    {isAi ? <span className="ai-badge">AI</span> : null}
                    {
                        renameId ?
                            <Form.Control
                                type="text"
                                defaultValue={name}
                                className="rename-form-control"
                                onClick={(e) => e.stopPropagation()}
                                onChange={handleInputChange}
                            />
                            :
                            <span className="ai-bot-text">{name}</span>
                    }
                </div>
                {confirmationId ?
                    <div className="confirm-cancel">
                        <span className="confirm" onClick={renameId ? (e) => handleRenameConfirm(e, id) : (e) => handleDeleteConfirm(e, id)}>
                            <i class="bi bi-check-lg"></i>
                        </span>
                        <span className="cancel" onClick={(e) => handleCancel(e, renameId)}>
                            <i className="bi bi-x-lg"></i>
                        </span>
                    </div>
                    :
                    <div >
                        <span className="rename" onClick={(e) => handleRenameOrDelete(e, id, true)}>
                            <i className="bi bi-pencil"></i>
                        </span>
                        {/* <span className="share" onClick={(e) => e.stopPropagation()}>
                            <i className="bi bi-box-arrow-up"></i>
                        </span> */}
                        <span className="delete" onClick={(e) => handleRenameOrDelete(e, id, false)}>
                            <i className="bi bi-trash"></i>
                        </span>
                    </div>
                }
            </div>
        </ListGroup.Item>
    );
}