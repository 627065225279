import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Label, ResponsiveContainer } from 'recharts';
import { getEquityCurve } from '../../utils/common/backtestResponseQuery';


export default function StackedEquityCurve({ data, activeYears, activeMarkets, currentYear, currentMarket }) {
    const years = activeYears.map(([year, _]) => year);
    const markets = activeMarkets.map(([market, _]) => market);
    let zippedEquityCurves;
    if (currentYear === 'All Years') {
        zippedEquityCurves = zipMarketEquityCurves(data, currentYear, markets);
    } else {
        zippedEquityCurves = zipAllEquityCurves(data, currentMarket, years, currentYear, markets);
    }
    const dataToPlot = zippedEquityCurves.map((profit, index) => {
        return {
            name: index,
            id: index,
            ...profit
        };
    });

    const activeElements = activeYears.concat(activeMarkets);
    const colorCode = {
        2022: "#8884d8",
        2021: "#82ca9d",
        2020: "#ffc658",
        2019: "#ff7f0e",
        2018: "#1f77b4",
        2017: "#ff4c4c",
        "6a": "#ff9999",
        "6b": "#99ff99",
        "6c": "#66b3ff",
        "6e": "#c2c2f0",
        "6j": "#ffcc99",
        "6n": "#ffb3e6",
        "Aggregated": "#6666ff",
        "6btc": "#c2f0c2"
    }

    return (
        <ResponsiveContainer width="100%" height={500}>
            <LineChart
                width={500}
                height={300}
                data={dataToPlot}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 15,
                }}
            >

                <CartesianGrid strokeDasharray="3 3" />
                <XAxis interval={99} dataKey="name">
                    <Label value="Trade #Id" offset={-10} position="insideBottom" />
                </XAxis>
                <YAxis>
                    <Label value="USD" angle={-90} position="left" offset={10} textAnchor="middle" />
                </YAxis>
                <Tooltip labelFormatter={(label) => `#Id: ${label}`} />
                {activeElements.map(([item, isActive]) => {
                    if (currentYear !== 'All Years') {
                        if (isActive && item !== currentMarket) {
                            if (years.includes(item)) {
                                return (<Line type="monotone" dataKey={item} stroke={colorCode[item]} dot={false} name={`${currentMarket} ${item}`} />);
                            }
                            return (
                                <Line type="monotone" dataKey={item} stroke={colorCode[item]} dot={false} name={`${item} ${currentYear}`} />
                            );
                        }
                    } else {
                        if (isActive) {
                            if (years.includes(item)) {
                                return (<Line type="monotone" dataKey={item} stroke={colorCode[item]} dot={false} name={`${currentMarket} ${item}`} />);
                            }
                            return (
                                <Line type="monotone" dataKey={item} stroke={colorCode[item]} dot={false} name={`${item} ${currentYear}`} />
                            );
                        }
                    }
                })}
            </LineChart>
        </ResponsiveContainer>
    );
}

function zipMarketEquityCurves(data, currentYear, markets) {
    const [lengthOfLongestEquityCurve, marketOfLongestEquityCurve] = markets.reduce((result, market) => {
        const equityArray = getEquityCurve(data, market, currentYear);
        if (result[0] < equityArray.length) {
            return [equityArray.length, market];
        } else {
            return result;
        }
    }, [0, 0]);
    const equalLengthDataForMarket = makeEquityCurvesLengthEqualForMarkets2(data, currentYear, markets, lengthOfLongestEquityCurve, marketOfLongestEquityCurve);

    const zipped = [...Array(lengthOfLongestEquityCurve).keys()].map(i => {
        return Object.entries(equalLengthDataForMarket).reduce((result, [item, equityArray]) => {
            const res = {};
            res[item] = Math.round(equityArray[i]);
            return {
                ...result,
                ...res
            };
        }, {});
    });

    return zipped;
}

function zipAllEquityCurves(data, currentMarket, years, currentYear, markets) {

    const [lengthOfLongestEquityCurve, yearOfLongestEquityCurve] = years.reduce((result, year) => {
        const equityArray = getEquityCurve(data, currentMarket, year);
        if (result[0] < equityArray.length) {
            return [equityArray.length, year];
        } else {
            return result;
        }
    }, [0, 0]);

    const equalLengthDataForYear = makeEquityCurvesLengthEqualForYears(data, currentMarket, years, lengthOfLongestEquityCurve, yearOfLongestEquityCurve);
    const equalLengthDataForMarket = makeEquityCurvesLengthEqualForMarkets(data, currentYear, markets, lengthOfLongestEquityCurve, yearOfLongestEquityCurve);
    const equalLengthItems = { ...equalLengthDataForYear, ...equalLengthDataForMarket };

    const zipped = [...Array(lengthOfLongestEquityCurve).keys()].map(i => {
        return Object.entries(equalLengthItems).reduce((result, [item, equityArray]) => {
            const res = {};
            res[item] = Math.round(equityArray[i]);
            return {
                ...result,
                ...res
            };
        }, {});
    });

    return zipped;
}

function makeEquityCurvesLengthEqualForYears(data, currentMarket, years, lengthOfLongestEquityCurve, yearOfLongestEquityCurve) {
    return years.reduce((result, year) => {
        const equityArray = getEquityCurve(data, currentMarket, year);
        const res = {};
        if (year !== yearOfLongestEquityCurve) {
            res[year] = equityArray.concat(Array(lengthOfLongestEquityCurve - equityArray.length).fill(equityArray[equityArray.length - 1]));
        } else {
            res[year] = equityArray;
        }
        return {
            ...result,
            ...res
        };
    }, {});
}

function makeEquityCurvesLengthEqualForMarkets(data, currentYear, markets, lengthOfLongestEquityCurve, yearOfLongestEquityCurve) {
    return markets.reduce((result, market) => {
        const equityArray = getEquityCurve(data, market, currentYear);
        const res = {};
        if (currentYear !== yearOfLongestEquityCurve) {
            res[market] = equityArray.concat(Array(lengthOfLongestEquityCurve - equityArray.length).fill(equityArray[equityArray.length - 1]));
        } else {
            res[market] = equityArray;
        }
        return {
            ...result,
            ...res
        };
    }, {});
}

function makeEquityCurvesLengthEqualForMarkets2(data, currentYear, markets, lengthOfLongestEquityCurve, marketOfLongestEquityCurve) {
    return markets.reduce((result, market) => {
        const equityArray = getEquityCurve(data, market, currentYear);
        const res = {};
        if (market !== marketOfLongestEquityCurve) {
            res[market] = equityArray.concat(Array(lengthOfLongestEquityCurve - equityArray.length).fill(equityArray[equityArray.length - 1]));
        } else {
            res[market] = equityArray;
        }
        return {
            ...result,
            ...res
        };
    }, {});
}