import { Card } from "react-bootstrap";
import NumberIncrementer from "../NumberIncrementer";

export default function Crv({ crv }) {
  if (crv === null) {
    // Happens when denominator, the avg loss, is zero
    return (
      <Card style={{ width: '18rem' }} className='mb-2'>
        <Card.Header>CRV</Card.Header>
        <Card.Body>
          <Card.Title>
            <h1 className='color-win'>
              <i class="bi bi-chevron-bar-expand distance-icon-kpi" />
              <i class="bi bi-infinity" />
            </h1>
          </Card.Title>
          <Card.Text>
            <span><i class="bi bi-chevron-double-up color-win" /></span> for every unit of risk, the potential reward is <i class="bi bi-infinity" /> units
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }
  const integralPart = Math.floor(crv);
  const colorCode = integralPart < 1 ? 'color-loss' : 'color-win';
  const target = (crv - integralPart) * 1000;
  const targetCapped = target.toFixed(0);

  // There are cases where target has vorstehende zeros, like 0.0009. In this case
  // we need to manually compute how many zeros to add, s.t. the number is shown correctely
  // Otherwise NumberIncrementer returns (0.0009 * 1000).toFixed(0) === 1 and we see 1.1 instead of 1.001
  let offset = '';
  if (Math.floor(targetCapped / 10) === 0) {
    offset = '00';
  } else if (Math.floor(targetCapped / 100) === 0) {
    offset = '0';
  }

  return (
    <Card style={{ width: '18rem' }} className='mb-2'>
      <Card.Header>CRV</Card.Header>
      <Card.Body>
        <Card.Title>
          <h1 className={colorCode}>
            <i class="bi bi-chevron-bar-expand distance-icon-kpi" />
            <strong>{integralPart}.{offset}<NumberIncrementer target={target} intervalTime={10} stepSize={10} /> </strong>
          </h1>
        </Card.Title>
        <Card.Text>
          <span><i class={`${integralPart < 1 ? "bi bi-chevron-double-down color-loss" : "bi bi-chevron-double-up color-win"}`} /></span> for every unit of risk, the potential reward is {crv.toFixed(3)} units
        </Card.Text>
      </Card.Body>
    </Card>
  );
}